import React from 'react';

const BulletPoints = ({
  data,
  pl,
  bgColor,
  isRoman,
  id,
  handleOnClick,
  isDot,
}) => {
  return (
    <div className="flex gap-2" style={{ paddingLeft: pl ? pl : '' }}>
      {isRoman ? (
        <>
          <div
            className={`Text16L font-[550] ${isDot ? 'w-6' : 'w-5'}`}
            style={{ color: bgColor ? bgColor : '' }}
          >
            {id}
            {isDot ? '' : '.'}
          </div>
        </>
      ) : (
        <>
          <p
            className="w-[6px] h-[6px] bg-TernaryT rounded-full mt-[10px]"
            style={{ backgroundColor: bgColor ? bgColor : '' }}
          ></p>
        </>
      )}
      <p
        className={`Text16L  ${
          isRoman ? 'w-[calc(100%-28px)]' : 'w-[calc(100%-14px)]'
        }`}
        dangerouslySetInnerHTML={{
          __html: data,
        }}
        onClick={handleOnClick}
      ></p>
    </div>
  );
};

export default BulletPoints;
