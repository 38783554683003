import React, { useState } from 'react';
import CloseIcon from '../../assets/images/CloseIcon.svg';
import Switch from 'react-switch';
import { useNavigate } from 'react-router-dom';
import { ButtonFilled } from '../Button';
import { DropDownArrow, EFLogo } from '../../assets/icons/icon';

const ManageCookies = ({ setManageClicked, handleSelection }) => {
  const navigate = useNavigate();
  const [openQuestionIndex, setOpenQuestionIndex] = useState(-1);
  const [data, setData] = useState([
    {
      question: 'Strictly Necessary Cookies',
      answer:
        'These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.',
      checked: true,
      id: 'Strictly_Cookies',
    },
    {
      question: 'Analytics Cookies',
      answer:
        'These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.',
      checked: false,
      id: 'analytics_ep',
    },
    {
      question: 'Functional Cookies',
      answer:
        'These cookies enable the website to provide enhanced functionality and personalisation. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly.',
      checked: false,
      id: 'functional_ep',
    },
    {
      question: 'Targeting Cookies',
      answer:
        'These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites. They do not store directly personal information, but are based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising.',
      checked: false,
      id: 'targeting_cookie_ep',
    },
  ]);
  const handleItemClick = (index) => {
    const newData = [...data];
    newData[index].checked = !newData[index].checked; // Toggle checked property
    setData(newData);
  };
  return (
    <div>
      <div className="flex justify-between pb-5">
        <div className="w-[110px]">
          <EFLogo />
        </div>
        <div
          className="cursor-pointer "
          onClick={() => setManageClicked(false)}
        >
          <img draggable="false" src={CloseIcon} alt="closeIcon" />
        </div>
      </div>
      <div className="max-h-[620px] max_sm:max-h-[410px] overflow-y-auto hideScrollbar">
        <div className="mt-4 flex flex-col gap-4">
          <p className="Text18Normal text-PrimaryT font-semibold">
            Privacy Preference Center
          </p>
          <p className="Text16Normal">
            When you visit any web site, it may store or retrieve information on
            your browser, mostly in the form of cookies. This information might
            be about you, your preferences or your device and is mostly used to
            make the site work as you expect it to. The information does not
            usually directly identify you, but it can give you a more
            personalized web experience.
          </p>
          <p className="Text16Normal">
            Because we respect your right to privacy, you can choose not to
            allow some types of cookies. Click on the different category
            headings to find out more and change our default settings. However,
            blocking some types of cookies may impact your experience of the
            site and the services we are able to offer.
          </p>
          <p className="Text16Normal">
            In some cases, data obtained from cookies is shared with third
            parties and may be considered a "sale of personal information" under
            the California Consumer Privacy Act (CCPA). You can exercise your
            right to opt-out of that sharing at any time by disabling cookies{" "}
            <span
              className="cursor-pointer Text16Normal text-ButtonPrimary underline"
              onClick={() => {
                navigate('/legal/cookie-policy')
                setTimeout(() => {
                  window.scrollTo(0, 0);
                }, 300);
              }}
            >
              Cookie Policy
            </span>
          </p>
          <ButtonFilled
            title="Allow all"
            width="100%"
            fontSize="16px"
            rounded="12px"
            onClick={() => handleSelection('allow-all')}
          />
        </div>
        <div className="mt-10">
          <p className="Text18Normal font-semibold text-PrimaryT">
            Manage Consent Preferences
          </p>
          <div className="mt-4 flex flex-col">
            {data?.map((list, index) => {
              return (
                <React.Fragment key={index + 1}>
                  <div className="pb-2 border-b-[1px] border-StrokePrimary mb-4">
                    <div className="flex justify-between">
                      <div
                        className={`flex gap-3 max_sm:gap-1 items-center cursor-pointer max_sm_380:items-start`}
                        onClick={() => {
                          if (openQuestionIndex === index) {
                            setOpenQuestionIndex(-1);
                          } else {
                            setOpenQuestionIndex(index);
                          }
                        }}
                      >
                        <p className="Text16Normal font-semibold text-PrimaryT w-max max_sm_380:w-[144px] max_esm:w-[126px] max_esm:text-[14px]">
                          {list.question}
                        </p>
                        <div
                          className={`cursor-pointer transition-all duration-200 ${
                            openQuestionIndex !== index
                              ? 'rotate-[360deg]'
                              : 'rotate-180'
                          }`}
                        >
                          <DropDownArrow />
                        </div>
                        {/* </div> */}
                      </div>
                      {index === 0 ? (
                        <>
                          <p className="max_sm:w-[50px] text-textExtraSmall font-UrbanistFont font-semibold text-ButtonPrimary">
                            Always Active
                          </p>
                        </>
                      ) : (
                        <>
                          <div>
                            <Switch
                              onColor="#FDBA12"
                              offColor="#F1F1F1"
                              offHandleColor="#ffffff"
                              onHandleColor="#ffffff"
                              handleDiameter={20}
                              borderRadius={17}
                              uncheckedIcon={
                                <div className="cookie-switch text-[11px] text-TernaryT font-UrbanistFont font-bold">
                                  OFF
                                </div>
                              }
                              checkedIcon={
                                <div className="cookie-switch text-[11px] text-white font-UrbanistFont font-bold">
                                  ON
                                </div>
                              }
                              onChange={() => handleItemClick(index)}
                              checked={list.checked}
                              className="react-switch"
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={`answerSection ${
                        openQuestionIndex === index ? 'open' : ''
                      }`}
                    >
                      <div
                        className={`mt-1 w-[80%] Text16Normal font-normal text-TernaryT`}
                      >
                        {list.answer}
                      </div>
                    </div>
                  </div>
                  <div></div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <div className="mt-4 flex gap-5 max_sm:flex-col">
          <ButtonFilled
            title="Reject all"
            width="100%"
            onClick={() => handleSelection('reject-all')}
          />
          <ButtonFilled
            title="Confirm my choices"
            width="100%"
            onClick={() => handleSelection('my-choice', data)}
          />
        </div>
      </div>
    </div>
  );
};

export default ManageCookies;
