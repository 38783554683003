import React, { useEffect } from 'react'
import LegalSideLayout from '../../Components/Legal/LegalSideLayout'
import WebAccessContent from '../../Components/Legal/LegalPageContent/WebAccessContent'
import { useLocation } from 'react-router-dom';

const WebAccessibility = () => {
  const location = useLocation();
  const state = location.state;
  useEffect(() => {
    if (state && state !== null) {
      window.scrollTo(0, 0);
    }
  }, [state]);

  var scrollPosition = localStorage.getItem('scrollWA');
  if (scrollPosition !== null) {
    window.scrollTo(0, parseInt(scrollPosition));
  }
  return (
    <>
      <LegalSideLayout>
        <WebAccessContent />
    </LegalSideLayout>
    </>
  )
}

export default WebAccessibility