import React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { EFLogo, HomeEllipse1 } from '../assets/icons/icon';
import { useWindowSize } from '../Hooks/useWindowSize';
import { ButtonFilled } from './Button';
import BackgroundFrame from '../assets/images/Homepage/DottedFrame.svg'

const Navbar = ({ navbarChangeColor }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { width: windowWidth } = useWindowSize();
  const isMobileview = windowWidth < 500;


  const handleClickScroll = (id) => {
    if (location.pathname !== '/') {
      navigate('/');
    }
    setTimeout(() => {
      const element = document.getElementById(id);

      if (element) {
        const elementPosition =
          element.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - 80;
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }, 500);
  };

  return (
    <div className="relative max-w-[1920px] m-auto overflow-hidden">
      {!navbarChangeColor && location.pathname === '/' && (
        <>
          <div className="absolute left-0 top-0 z-[-1]">
            <img src={BackgroundFrame} alt="background" />
          </div>
          <div className="absolute top-0 right-[4%] opacity-60 rotate-45 blur-[250px]">
            <HomeEllipse1
              style={{
                width: isMobileview ? '210' : '320',
                height: isMobileview ? '300' : '410',
              }}
            />
          </div>
        </>
      )}
      <div className="relative z-[20] w-[100%] m-auto flex h-[66px] items-center max_md:px-10 max_sm:px-5 max-w-[1240px] max_xl:max-w-[980px]">
        <div className="FLEX_CENTER w-full justify-between ">
          <div className="anime-fade-in flex items-center justify-between cursor-pointer">
            <div
              className="z-40 w-[140px] max_sm:w-[90px]"
              onClick={() => {
                if (location.pathname === '/') {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  });
                } else {
                  navigate('/');
                  setTimeout(() => {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: 'smooth',
                    });
                  }, 100);
                }
              }}
            >
              <EFLogo width={isMobileview ? 98 : 145} />
            </div>
          </div>

          <div className="relative FLEX_CENTER flex gap-x-[20px] max_sm:gap-x-2">
            {/* {MenuList.map((menu, index) => {
              return (
                <div key={index}>
                  <NavLink
                    className="z-[90] flex h-[50px] w-full items-center justify-end no-underline"
                    to={menu.link}
                    onClick={(e) => handleClick(menu.link, e)}
                  >
                    {({ isActive }) => (
                      <>
                        <div
                          className={`cursor-pointer font-UrbanistFont text-textSmall font-normal ${
                            navbarChangeColor
                              ? 'text-SecondaryT hover:text-PrimaryT'
                              : location.pathname === '/'
                              ? 'text-white'
                              : 'text-SecondaryT hover:text-PrimaryT'
                          }`}
                          // ref={outSideRef}
                        >
                          {menu.name}
                        </div>
                      </>
                    )}
                  </NavLink>
                </div>
              );
            })} */}
            {/* Popup menu  */}
            <p className="Text18Normal font-semibold text-ButtonPrimary cursor-pointer max_sm:text-textSmall" onClick={()=>navigate('/sign-in')}>
              Log in
            </p>
            <div className="z-[60]">
              <ButtonFilled
                title="Get started"
                onClick={() => handleClickScroll('ContactId')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
