import React from 'react';
import BgImage from '../../assets/images/Homepage/DottedFrame.png';
import { HomeEllipse1 } from '../../assets/icons/icon';
import { useWindowSize } from '../../Hooks/useWindowSize';

const HerosectionBg = ({ children }) => {
   const { width: windowWidth } = useWindowSize();
   const isMobileView = windowWidth <= 600;
  return (
    <div className="w-full relative max-w-[1920px] h-[calc(100vh-66px)] max-h-[1080px] max_md:h-auto overflow-y-visible overflow-x-hidden">
      <div className="absolute w-full h-full inset-0 z-[-1] overflow-hidden">
        <img src={BgImage} alt="BgImage" />
      </div>

      <div className="absolute top-2 right-[4%] opacity-60 rotate-45 blur-[250px]">
        <HomeEllipse1
          style={{
            width: isMobileView ? '210' : '320',
            height: isMobileView ? '300' : '410',
          }}
        />
      </div>
      {/* <div className="absolute top-[30%] max_md:top-[50%] left-[8%] opacity-60 rotate-[-30deg] blur-[250px]">
        <HomeEllipse2 />
      </div> */}
      {children}
    </div>
  );
};

export default HerosectionBg;
