import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EFLogo } from '../../assets/icons/icon';
import EmailPassword from '../../Components/Login/EmailPassword';
import GradientBg from '../../Components/GradientBg';

const SignInPage = () => {
  const navigate = useNavigate();
  const [userInput, setUserInput] = useState({
    email: '',
    password: '',
  });

  const errorInitial = {
    email: false,
    password: false,
  };
  const [errors, setErrors] = useState(errorInitial);
  const [showErr, setShowErr] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInput({ ...userInput, [name]: value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const isContinueDisable =
    userInput?.email?.length === 0 || userInput?.password?.length === 0;

  const validateForm = (type) => {
    const newErrors = {};
    // Check for non-empty fields
    if (type === 'email_details') {
      newErrors.email =
        userInput.email.length < 5 || userInput.email.length > 100;
      newErrors.password = !userInput.password;

      if (!newErrors.email) {
        newErrors.email = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userInput.email);
      }
    }
    return newErrors;
  };
  const SignInFun = () => {
    const formErrors = validateForm('email_details');
    setErrors(formErrors);
    const hasErrors = Object.values(formErrors).some((error) => error);
    if (!hasErrors) {
      setShowErr(true);
    }
  };

  const handleClickScroll = (id, name) => {
    if (name === 'signup') {
      navigate('/', { state: 'contact' });
    } else {
      navigate('/');
    }
    setTimeout(() => {
      const element = document.getElementById(id);

      if (element) {
        const elementPosition =
          element.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - 80;
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }, 500);
  };
  return (
    <>
      <GradientBg>
        <div className="w-full flex justify-center items-center h-full max_720:px-10 max_sm:px-5">
          <div className="max_720:w-full">
            <div
              className="w-[600px] max_800:px-10 max_720:w-full h-fit rounded-[20px] bg-[#fff] py-[30px] px-[60px] flex flex-col items-center max_sm:px-5"
              style={{ boxShadow: '0px 20px 150px 0px #3C425714' }}
            >
              <div className="cursor-pointer" onClick={() => navigate('/')}>
                <EFLogo />
              </div>

              <p className="mt-[30px] text-[26px] font-bold font-UrbanistFont text-PrimaryT leading-[33px] max_sm:text-[20px]">
                Sign in to your account
              </p>
              <div className="flex flex-col gap-4 mt-7 w-full max_sm:mt-5">
                <EmailPassword
                  userInput={userInput}
                  handleChange={handleChange}
                  error={errors}
                  isContinueDisable={isContinueDisable}
                  onSignIn={SignInFun}
                  showErr={showErr}
                  handleClickScroll={handleClickScroll}
                />
              </div>
            </div>

            <div className="w-[600px] max_720:w-full flex gap-4 max_sm:gap-2 mt-5 items-center">
              <p className="text14 cursor-pointer max_sm:text-[14px]">© Epifony</p>
              <p className="w-[4px] h-[4px] bg-PrimaryT rounded-full"></p>
              <p
                className="text14 cursor-pointer max_sm:text-[14px]"
                onClick={() => handleClickScroll('ContactId')}
              >
                Contact
              </p>
              <p className="w-[4px] h-[4px] bg-PrimaryT rounded-full"></p>
              <p
                className="text14 cursor-pointer max_sm:text-[14px]"
                onClick={() => {
                  navigate('/legal/privacy-policy');
                  setTimeout(() => {
                    window.scrollTo(0, 0);
                  }, 300);
                }}
              >
                Privacy Policy
              </p>
            </div>
          </div>
        </div>
      </GradientBg>
    </>
  );
};

export default SignInPage;
