import React from 'react';
import { ButtonFilled } from '../Button';
// import HeroImage from '../../assets/images/Homepage/HeroSection.svg';
// import HeroImageRes from '../../assets/images/Homepage/HerosectionRes.svg';

const BannerSection = ({ handleClick }) => {
  return (
    <div className="max-w-[1920px] m-auto w-full relative pt-[140px] max_xl:pt-[90px] max_xl:pb-[70px] max_md:py-10 max_md:mb-10 max_sm:mb-5">
      <div className="relative max-w-[1240px] max_xl:max-w-[980px] m-auto flex items-center max_md:flex-col max_md:px-10 max_sm:px-5">
        <div className="max_md:w-full max-w-[585px] max_xl:max-w-[485px] max_md:max-w-full">
          <h1 className="m-auto headingText max_sm:text-center">
            Transform
            <br className="max_xl:hidden" /> your contacts into connections
          </h1>

          <p className="Text20 mt-10 max_md:mt-[30px] max_sm:mt-5 max_sm:text-center">
            Efficiently organize and access your contacts to make every
            interaction count.
          </p>
          <div className="mt-10 w-fit max_md:mt-[30px] max_sm:mt-5 max_sm:m-auto">
            {/* <a
              href="https://calendly.com/dcdial-sales/30min"
              rel="noopener noreferrer"
              target="_blank"
            > */}
            <ButtonFilled
              title="Book a demo"
              onClick={() => handleClick('ContactId')}
            />
            {/* </a> */}
          </div>

          {/* MOBILE IMAGE */}
          <div className="hidden max_md:block mt-[60px] max_sm:mt-10">
            <img
              src="https://images.dcdial.com/epifony.com/HerosectionRes.svg"
              alt="Banner_img"
              className="w-full"
              loading="lazy"
            />
          </div>
        </div>
      </div>
      <div className="absolute right-0 top-[120px] max_xl:top-[80px] w-[40%] max_1680:w-[43%] max_1440:w-[45%] max_xl:w-[46%] max_1160:w-[47%] max_md:hidden">
        <img
          src="https://images.dcdial.com/epifony.com/HeroSection.svg"
          alt="Banner_img"
          className="w-full"
        />
      </div>
    </div>
  );
};

export default BannerSection;
