import React, { useState } from 'react';
import CommonSection from '../Common/CommonSection';
import DownArrow from '../../assets/images/arrow-down.svg';
import ArrowUp from '../../assets/images/arrow-up.svg';

const FAQ = () => {
    const [openQuestionIndex, setOpenQuestionIndex] = useState(-1);
  const [FAQList] = useState([
    {
      question: 'What is Epifony?',
      answer:
        'Epifony is a customer relationship management (CRM) platform designed to streamline your business operations and enhance customer interactions. It provides tools to manage sales, customer service, and marketing processes from a single interface.',
    },
    {
      question: 'Who will benefit from using it?',
      answer:
        "Epifony is ideal for businesses of all sizes that want to improve customer relationships and increase efficiency. It's particularly beneficial for teams looking to consolidate multiple tools into one comprehensive CRM system.",
    },
    {
      question: 'What are the key features?',
      answer:
        'Key features include comprehensive contact management, sales pipeline visualization, automated marketing campaigns, and robust reporting tools. Additionally, it offers a user-friendly dashboard to monitor customer interactions and performance metrics.',
    },
    {
      question: 'Can I customize workflows in Epifony?',
      answer:
        'Yes, Epifony allows users to customize workflows to fit their specific business needs. You can create and modify workflows to automate tasks and ensure that your business processes are running as efficiently as possible.',
    },
    {
      question: 'Does it integrate with other tools?',
      answer:
        'Yes, Epifony integrates with email platforms, marketing software, collaboration tools, project management software, and more, enabling a seamless workflow across various business operations.',
    },
  ]);
  return (
    <CommonSection>
      <div>
        <h2 className="Text60 text-center">FAQ</h2>
        <div className="flex flex-col mt-5">
          {FAQList?.map((list, index) => {
            return (
              <React.Fragment key={index + 1}>
                <div
                  className="py-10 max_md:py-5 border-b-[1px] border-StrokePrimary cursor-pointer"
                  onClick={() => {
                    if (openQuestionIndex === index) {
                      setOpenQuestionIndex(-1);
                    } else {
                      setOpenQuestionIndex(index);
                    }
                  }}
                >
                  <div className="flex justify-between">
                    <div
                      className={`Text24 ${
                        openQuestionIndex === index
                          ? 'text-PrimaryT font-semibold'
                          : 'text-TernaryT font-normal'
                      }  w-[95%]`}
                    >
                      {list.question}
                    </div>
                    <div className="w-[30px]">
                      <img
                        src={openQuestionIndex === index ? ArrowUp : DownArrow}
                        alt="Downarrow"
                        className={`${
                          openQuestionIndex !== index
                            ? 'rotate-[360deg]'
                            : 'rotate-180'
                        } transition-all`}
                      />
                    </div>
                  </div>
                  <div
                    className={`answerSection ${
                      openQuestionIndex === index ? 'open' : ''
                    }`}
                  >
                    <div className={`Text20 mt-2 w-[90%] max_sm:w-[97%] `}>
                      {list.answer}
                    </div>
                  </div>
                </div>
                <div></div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </CommonSection>
  );
};

export default FAQ;
