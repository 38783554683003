import React from 'react'
import CommonSection from '../../Components/Common/CommonSection'
import LegalGroup from '../../Components/Legal/LegalGroup';

const LegalPage = () => {
  return (
    <CommonSection>
      <div>
        <h1 className="headingText text-center max-w-[940px] m-auto">Legal</h1>
        <p className="Text20 text-center mt-10">
          We are committed to providing clear and transparent information
          regarding legal matters pertaining to our services. Please read
          through the following sections carefully to understand your rights and
          responsibilities when using our platform.
        </p>

        <div className='mt-[60px]'>
          <LegalGroup />
        </div>
      </div>
    </CommonSection>
  );
}

export default LegalPage