export const ForCustomer = (props) => {
  return (
    <>
      <svg
        {...props}
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.2663 18.1167C20.0996 18.1 19.8996 18.1 19.7163 18.1167C15.7496 17.9834 12.5996 14.7334 12.5996 10.7334C12.5996 6.65004 15.8996 3.33337 19.9996 3.33337C24.0829 3.33337 27.3996 6.65004 27.3996 10.7334C27.3829 14.7334 24.2329 17.9834 20.2663 18.1167Z"
          stroke="#FDBA12"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.9332 24.2666C7.89987 26.9666 7.89987 31.3666 11.9332 34.05C16.5165 37.1166 24.0332 37.1166 28.6165 34.05C32.6499 31.35 32.6499 26.95 28.6165 24.2666C24.0499 21.2166 16.5332 21.2166 11.9332 24.2666Z"
          stroke="#FDBA12"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
export const ForDevelopers = (props) => {
  return (
    <>
      <svg
        {...props}
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 18.0417C24.0417 18.0417 27.375 14.75 27.375 10.6667C27.375 6.62504 24.0417 3.33337 20 3.33337C15.9583 3.33337 12.625 6.62504 12.625 10.7084C12.625 14.75 15.9583 18.0417 20 18.0417ZM20 5.00004C23.125 5.00004 25.7083 7.54171 25.7083 10.7084C25.7083 13.8334 23.1667 16.4167 20 16.4167C16.875 16.4167 14.2917 13.875 14.2917 10.7084C14.2917 7.54171 16.875 5.00004 20 5.00004Z"
          fill="#FDBA12"
          stroke="#FDBA12"
          strokeWidth="0.5"
        />
        <path
          d="M26.4163 18.4584C26.1663 18.4584 25.958 18.5417 25.7913 18.7084C24.2497 20.2084 22.1663 21.0417 19.9997 21.0417C17.833 21.0417 15.7913 20.2084 14.208 18.7084C14.0413 18.5417 13.7913 18.4584 13.583 18.4584C9.04134 18.7917 5.45801 22.625 5.45801 27.2084V31.25C5.45801 34.25 7.87467 36.6667 10.8747 36.6667H29.083C32.083 36.6667 34.4997 34.25 34.4997 31.25V27.2084C34.5413 22.625 30.958 18.7917 26.4163 18.4584ZM32.8747 31.25C32.8747 33.3334 31.208 35 29.1247 35H10.8747C8.79134 35 7.12467 33.3334 7.12467 31.25V27.2084C7.12467 23.625 9.83301 20.625 13.333 20.1667C15.1663 21.8334 17.4997 22.7084 19.9997 22.7084C22.4997 22.7084 24.833 21.7917 26.6663 20.1667C30.1663 20.5834 32.8747 23.625 32.8747 27.2084V31.25Z"
          fill="#FDBA12"
          stroke="#FDBA12"
          strokeWidth="0.5"
        />
        <path
          d="M24.6247 26C24.2913 25.6667 23.7913 25.6667 23.458 26C23.1247 26.3333 23.1247 26.8333 23.458 27.1667L24.9997 28.75L23.458 30.2917C23.1247 30.625 23.1247 31.125 23.458 31.4583C23.6247 31.625 23.833 31.7083 24.0413 31.7083C24.2497 31.7083 24.458 31.625 24.6247 31.4583L26.7497 29.3333C27.083 29 27.083 28.5 26.7497 28.1667L24.6247 26Z"
          fill="#FDBA12"
          stroke="#FDBA12"
          strokeWidth="0.5"
        />
        <path
          d="M21.5828 23.7917C21.1245 23.6667 20.6662 23.9167 20.5412 24.375L18.2078 32.6667C18.0828 33.125 18.3328 33.5834 18.7912 33.7084C18.8745 33.75 18.9578 33.75 18.9995 33.75C19.3745 33.75 19.7078 33.5 19.7912 33.125L22.1245 24.8334C22.2495 24.375 21.9995 23.9167 21.5828 23.7917Z"
          fill="#FDBA12"
          stroke="#FDBA12"
          strokeWidth="0.5"
        />
        <path
          d="M16.5417 26.0001C16.2083 25.6668 15.7083 25.6668 15.375 26.0001L13.25 28.1251C12.9167 28.4585 12.9167 28.9585 13.25 29.2918L15.375 31.4168C15.5417 31.5835 15.75 31.6668 15.9583 31.6668C16.1667 31.6668 16.375 31.5835 16.5417 31.4168C16.875 31.0835 16.875 30.5835 16.5417 30.2501L15 28.7501L16.5417 27.2085C16.875 26.8751 16.875 26.3335 16.5417 26.0001Z"
          fill="#FDBA12"
          stroke="#FDBA12"
          strokeWidth="0.5"
        />
      </svg>
    </>
  );
};
export const ForEveryone = (props) => {
  return (
    <>
      <svg
        {...props}
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.9998 11.9334C29.8998 11.9167 29.7831 11.9167 29.6831 11.9334C27.3831 11.85 25.5498 9.96671 25.5498 7.63338C25.5498 5.25004 27.4665 3.33337 29.8498 3.33337C32.2331 3.33337 34.1498 5.26671 34.1498 7.63338C34.1331 9.96671 32.2998 11.85 29.9998 11.9334Z"
          stroke="#FDBA12"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.2834 24.0667C30.5667 24.4501 33.0834 24.0501 34.8501 22.8667C37.2001 21.3001 37.2001 18.7334 34.8501 17.1667C33.0667 15.9834 30.5167 15.5834 28.2334 15.9834"
          stroke="#FDBA12"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.95078 11.9334C10.0508 11.9167 10.1674 11.9167 10.2674 11.9334C12.5674 11.85 14.4008 9.96671 14.4008 7.63338C14.4008 5.25004 12.4841 3.33337 10.1008 3.33337C7.71745 3.33337 5.80078 5.26671 5.80078 7.63338C5.81745 9.96671 7.65078 11.85 9.95078 11.9334Z"
          stroke="#FDBA12"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.6671 24.0667C9.38372 24.4501 6.86706 24.0501 5.10039 22.8667C2.75039 21.3001 2.75039 18.7334 5.10039 17.1667C6.88372 15.9834 9.43372 15.5834 11.7171 15.9834"
          stroke="#FDBA12"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.9998 24.3833C19.8998 24.3667 19.7831 24.3667 19.6831 24.3833C17.3831 24.3 15.5498 22.4167 15.5498 20.0833C15.5498 17.7 17.4665 15.7833 19.8498 15.7833C22.2331 15.7833 24.1498 17.7167 24.1498 20.0833C24.1331 22.4167 22.2998 24.3167 19.9998 24.3833Z"
          stroke="#FDBA12"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.1502 29.6333C12.8002 31.2 12.8002 33.7667 15.1502 35.3333C17.8169 37.1167 22.1835 37.1167 24.8502 35.3333C27.2002 33.7667 27.2002 31.2 24.8502 29.6333C22.2002 27.8667 17.8169 27.8667 15.1502 29.6333Z"
          stroke="#FDBA12"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
export const InfoIcon = ({ color = '#FDBA12' }) => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.0003 1.66663C14.6028 1.66663 18.3337 5.39746 18.3337 9.99996C18.3337 14.6025 14.6028 18.3333 10.0003 18.3333C5.39783 18.3333 1.66699 14.6025 1.66699 9.99996C1.66699 5.39746 5.39783 1.66663 10.0003 1.66663ZM10.0003 13.3333C9.77931 13.3333 9.56735 13.4211 9.41107 13.5774C9.25479 13.7336 9.16699 13.9456 9.16699 14.1666C9.16699 14.3876 9.25479 14.5996 9.41107 14.7559C9.56735 14.9122 9.77931 15 10.0003 15C10.2213 15 10.4333 14.9122 10.5896 14.7559C10.7459 14.5996 10.8337 14.3876 10.8337 14.1666C10.8337 13.9456 10.7459 13.7336 10.5896 13.5774C10.4333 13.4211 10.2213 13.3333 10.0003 13.3333ZM10.0003 5.41663C9.19915 5.41663 8.43079 5.73489 7.86427 6.30141C7.29776 6.86792 6.97949 7.63628 6.97949 8.43746C6.97949 8.65847 7.06729 8.87043 7.22357 9.02671C7.37985 9.18299 7.59181 9.27079 7.81283 9.27079C8.03384 9.27079 8.2458 9.18299 8.40208 9.02671C8.55836 8.87043 8.64616 8.65847 8.64616 8.43746C8.64643 8.19166 8.71361 7.95056 8.84048 7.74003C8.96735 7.5295 9.14913 7.35747 9.36633 7.24239C9.58353 7.12731 9.82796 7.07352 10.0734 7.08678C10.3188 7.10005 10.5561 7.17987 10.7596 7.31769C10.9631 7.45551 11.1253 7.64612 11.2287 7.8691C11.3322 8.09208 11.373 8.33901 11.3468 8.58341C11.3206 8.82781 11.2283 9.06046 11.0799 9.25643C10.9315 9.4524 10.7326 9.60428 10.5045 9.69579C9.94116 9.92079 9.16699 10.4975 9.16699 11.4583V11.6666C9.16699 11.8876 9.25479 12.0996 9.41107 12.2559C9.56735 12.4122 9.77931 12.5 10.0003 12.5C10.2213 12.5 10.4333 12.4122 10.5896 12.2559C10.7459 12.0996 10.8337 11.8876 10.8337 11.6666C10.8337 11.4633 10.8753 11.3616 11.0512 11.275L11.1237 11.2416C11.7743 10.9799 12.3137 10.4999 12.6493 9.88406C12.9848 9.26819 13.0957 8.55478 12.9629 7.86611C12.8301 7.17745 12.4619 6.55643 11.9214 6.10952C11.3808 5.66261 10.7017 5.41765 10.0003 5.41663Z"
          fill={color}
        />
      </svg>
    </>
  );
};
