import { useRef, useState } from 'react';
import { DropDownArrow, ErrorIcon } from '../assets/icons/icon';
import { useOnClickOutside } from '../Hooks/useOutsideClick';
import NotVisible from '../assets/images/Login/Notvisible.svg';
import Visible from '../assets/images/Login/visible.svg';

export const InputField = ({
  type,
  placeholder,
  handleOnChange,
  name,
  error,
  value,
  label,
  isRequired,
}) => {
  return (
    <div>
      <p className="text-textSmall max_sm:text-textExtraSmall font-UrbanistFont font-normal text-PrimaryT mb-2">
        {label}
        {isRequired && <span className="text-[#FF0000]">*</span>}:
      </p>
      <input
        style={{ border: error ? '1px solid #FF0000' : '' }}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={(e) => handleOnChange(e, name)}
        className={`w-full outline-none border-[1px] border-StrokeBorder py-3 px-5 rounded-[12px] font-UrbanistFont text-textExtraSmall text-PrimaryT font-normal`}
      />
    </div>
  );
};

export const InputTextArea = ({
  type,
  placeholder,
  handleOnChange,
  name,
  error,
  value,
  label,
  isRequired,
}) => {
  return (
    <div>
      <p className="text-textSmall max_sm:text-textExtraSmall font-UrbanistFont font-normal text-PrimaryT mb-2">
        {label}
        {isRequired && <span className="text-[#FF0000]">*</span>}:
      </p>
      <textarea
        style={{ border: error ? '1px solid #FF0000' : '' }}
        type={type}
        value={value}
        rows={3}
        placeholder={placeholder}
        onChange={(e) => handleOnChange(e, name)}
        className={`w-full outline-none border-[1px] border-StrokeBorder py-3 px-5 rounded-[12px] font-UrbanistFont text-textExtraSmall text-PrimaryT font-normal resize-none`}
      />
    </div>
  );
};

export const SelectBox = ({
  placeholder,
  data,
  handleOnChange,
  name,
  value,
  error,
  isUpperCase,
  label,
}) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, () => {
    if (open) {
      setOpen(false);
    }
  });
  return (
    <div>
      <p className="text-textSmall max_sm:text-textExtraSmall font-UrbanistFont font-normal text-PrimaryT mb-2">
        {label}:
      </p>
      <div
        style={{ border: error ? '1px solid #FF0000' : '' }}
        className={`relative border-[1px] border-StrokeBorder py-3 px-5 rounded-[12px] font-UrbanistFont text-textExtraSmall  font-normal ${
          value ? 'text-PrimaryT' : 'text-DisableText'
        }`}
        onClick={() => setOpen(!open)}
        ref={dropdownRef}
        name={name}
      >
        {value ? value : placeholder}
        <div
          className={`absolute right-5 top-3 cursor-pointer transition-transform duration-200 ${
            open ? 'rotate-180' : ''
          }`}
        >
          <DropDownArrow />
        </div>

        {open && (
          <>
            <div className="absolute top-14 left-0 w-full rounded-[10px] py-1 flex flex-col gap-[2px] bg-white z-30 shadow-[0px_8px_20px_0px_#D9D9D980]">
              {data?.map((inquiry, index) => (
                <p
                  className={`py-2 text-textExtraSmall font-normal font-UrbanistFont text-TernaryT px-3 hover:text-PrimaryT hover:bg-BgSecondary cursor-pointer ${
                    isUpperCase ? 'uppercase' : ''
                  }`}
                  key={index + 1}
                  onClick={(e) => handleOnChange(inquiry.name, name)}
                >
                  {inquiry.name}
                </p>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const TextField = ({
  type,
  placeholder,
  handleOnChange,
  name,
  error,
  value,
  label,
  errorMsg,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  return (
    <div>
      <p className="labelClass">{label}</p>
      <div className="w-full relative">
        <input
          autoComplete="new-password"
          style={{ border: error ? '1px solid #FF0000' : '' }}
          type={passwordVisible && name === 'password' ? 'text' : type}
          value={value}
          name={name}
          placeholder={placeholder}
          onChange={(e) => handleOnChange(e)}
          className={`w-full outline-none border-[1px] border-StrokePrimary py-[15px] rounded-[7px] font-UrbanistFont text-[15px] text-PrimaryT font-normal placeholder:text-DisableText ${
            type === 'password' ? 'pl-[15px] pr-[42px]' : 'px-[15px]'
          }`}
        />

        {type === 'password' && (
          <>
            <div
              className="absolute top-[14px] right-[15px] cursor-pointer"
              onClick={() => setPasswordVisible(!passwordVisible)}
            >
              <img
                src={passwordVisible ? Visible : NotVisible}
                alt="visibleIcon"
              />
            </div>
          </>
        )}
      </div>
      {error && (
        <div className="flex gap-1 items-center mt-[1px]">
          <ErrorIcon />
          <p className="font-UrbanistFont text-[#ff0000] text-[13px] font-normal ml-[2px] max_sm:ml-0 mt-[1px]">
            {errorMsg}
          </p>
        </div>
      )}
    </div>
  );
};
