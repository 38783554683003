import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TitleWithText from '../TitleWithText';
import BulletPoints from '../BulletPoints';

const ProperUseOfDCService = ({ id, handleOnClick }) => {
  const { t } = useTranslation();
  const [data, setData] = useState({
    list1: [],
    list2: [],
  });
  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      list1: getTranslatedList(t, 'list1'),
      list2: getTranslatedList(t, 'list2'),
    }));
  }, [t]);
  return (
    <TitleWithText
      title={t('UsageAVService')}
      count={6}
      text={[t('Usage_T')]}
      id={id}
    >
      <div className="mt-4 flex flex-col gap-4">
        <p className="Text16L">{t('Usage_T1')}</p>
        {data?.list1?.map((list, index) => (
          <BulletPoints data={list} key={index} pl="24px" />
        ))}
        <p className="Text16L">{t('Usage_T2')}</p>
        <p className="Text16L">{t('Usage_T3')}</p>

        <p
          className="Text16L"
          dangerouslySetInnerHTML={{ __html: t('Usage_T4') }}
          onClick={handleOnClick}
        ></p>
        <p className="Text16L">{t('Usage_T5')}</p>
        {data?.list2?.map((list, index) => (
          <BulletPoints
            data={list.text}
            key={index}
            pl="24px"
            isRoman
            id={list.id}
          />
        ))}
      </div>
    </TitleWithText>
  );
};
const getTranslatedList = (t, key) => {
  const lists = {
    list1: [
      t('Usage_1'),
      t('Usage_2'),
      t('Usage_3'),
      t('Usage_4'),
      t('Usage_5'),
      t('Usage_6'),
      t('Usage_7'),
      t('Usage_8'),
      t('Usage_9'),
      t('Usage_10'),
      t('Usage_11'),
      t('Usage_12'),
      t('Usage_13'),
      t('Usage_14'),
      t('Usage_15'),
      t('Usage_16'),
      t('Usage_17'),
    ],
    list2: [
      {
        id: 'i',
        text: t('Usage_Will_Not_1'),
      },
      {
        id: 'ii',
        text: t('Usage_Will_Not_2'),
      },
      {
        id: 'iii',
        text: t('Usage_Will_Not_3'),
      },
      {
        id: 'iv',
        text: t('Usage_Will_Not_4'),
      },
      {
        id: 'v',
        text: t('Usage_Will_Not_5'),
      },
      {
        id: 'vi',
        text: t('Usage_Will_Not_6'),
      },
      {
        id: 'vii',
        text: t('Usage_Will_Not_7'),
      },
      {
        id: 'viii',
        text: t('Usage_Will_Not_8'),
      },
    ],
  };

  return lists[key];
};

export default ProperUseOfDCService;
