import React, { useState } from 'react';
import CommonSection from '../Common/CommonSection';
import { ButtonFilled } from '../Button';
import {
  AccessControl,
  Roles,
  ShiftManage,
  UserLevel,
} from '../../assets/icons/icon';

const TeamSynchronize = ({ handleClick }) => {
  const [teamData] = useState([
    {
      icon: <UserLevel />,
      name: 'User Levels',
      text: 'Tailor access with specific roles like admin, manager, and agent, each with unique permissions to match their responsibilities.',
    },
    {
      icon: <Roles />,
      name: 'Roles & Permissions',
      text: 'Swiftly add, remove, or modify user accounts, designate roles, and customize access rights.',
    },
    {
      icon: <AccessControl />,
      name: 'Access Control',
      text: 'Strengthen security by allowing only authorized personnel to access critical systems.',
    },
    {
      icon: <ShiftManage />,
      name: 'Shift Management',
      text: 'Use Shift Schedules to set work hours and assign shifts, ensuring optimal team deployment.',
    },
  ]);
  return (
    <CommonSection>
      <div className="flex justify-between items-center max_md:flex-col max_md:gap-[60px]">
        <div className="max-w-[420px] flex flex-col gap-6 max_md:max-w-full max_md:items-center">
          <h2 className="Text60">Team synchronization</h2>
          <p className="Text20 max_md:text-center">
            Ensure secure and regulated entry to data and system
            functionalities, simplifying administration while safeguarding your
            information.
          </p>
          <div className="w-max">
            <ButtonFilled
              title="Learn more"
              onClick={() => handleClick('ContactId')}
            />
          </div>
        </div>

        <div className="max-w-[580px] max_xl:max-w-[480px] flex flex-col gap-[30px] max_md:max-w-[580px] max_md:m-auto max_720:max-w-[500px] max_600:hidden">
          {/* FIRST */}
          <div className="flex gap-10 max_xl:gap-[30px] max_md:gap-10">
            <div className="py-[26px] max_xl:py-5 w-[250px] max_xl:w-[220px] max_md:w-[250px]">
              <div>
                <UserLevel />
              </div>
              <h3 className="Text20 text-PrimaryT font-semibold mt-6">
                User Levels
              </h3>
              <p className="Text16Normal mt-2">
                Tailor access with specific roles like admin, manager, and
                agent, each with unique permissions to match their
                responsibilities.
              </p>
            </div>
            <div className="h-[250px] w-[0.5px] bg-StrokePrimary"></div>
            <div className="py-[26px] max_xl:py-5 w-[250px] max_xl:w-[220px] max_md:w-[250px]">
              <div>
                <Roles />
              </div>
              <h3 className="Text20 text-PrimaryT font-semibold mt-6">
                Roles & Permissions
              </h3>
              <p className="Text16Normal mt-2">
                Swiftly add, remove, or modify user accounts, designate roles,
                and customize access rights.
              </p>
            </div>
          </div>
          {/*Horizontal LINES */}
          <div className="flex gap-10 max_xl:gap-[30px] max_md:gap-12">
            <div className="w-[270px] h-[1px] bg-StrokePrimary"></div>
            <div className="w-[270px] h-[1px] bg-StrokePrimary"></div>
          </div>
          {/* Second */}
          <div className="flex gap-10 max_xl:gap-[30px] max_md:gap-10">
            <div className="py-[26px] max_xl:py-5 w-[250px] max_xl:w-[220px] max_md:w-[250px]">
              <div>
                <AccessControl />
              </div>
              <h3 className="Text20 text-PrimaryT font-semibold mt-6">
                Access Control
              </h3>
              <p className="Text16Normal mt-2">
                Strengthen security by allowing only authorized personnel to
                access critical systems.
              </p>
            </div>
            <div className="h-[250px] w-[0.5px] bg-StrokePrimary"></div>
            <div className="py-[26px] max_xl:py-5 w-[250px] max_xl:w-[220px] max_md:w-[250px]">
              <div>
                <ShiftManage />
              </div>
              <h3 className="Text20 text-PrimaryT font-semibold mt-6">
                Shift Management
              </h3>
              <p className="Text16Normal mt-2">
                Use Shift Schedules to set work hours and assign shifts,
                ensuring optimal team deployment.
              </p>
            </div>
          </div>
        </div>

        {/* Mobile design */}
        <div className="hidden max_600:block">
          <div className="flex flex-col gap-10">
            {teamData.map((data, index) => (
              <React.Fragment key={index}>
                <div className="">
                  <div>{data.icon}</div>
                  <h3 className="Text20 text-PrimaryT font-semibold mt-6">
                    {data.name}
                  </h3>
                  <p className="Text16Normal mt-2">{data.text}</p>
                </div>
                <div
                  className={` ${
                    index === teamData.length - 1
                      ? ''
                      : 'w-full h-[1px] bg-StrokePrimary'
                  }`}
                ></div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </CommonSection>
  );
};

export default TeamSynchronize;
