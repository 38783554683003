import React, { useEffect } from 'react';
import LegalSideLayout from '../../Components/Legal/LegalSideLayout';
import DeveloperTermsContent from '../../Components/Legal/LegalPageContent/DeveloperTermsContent';
import { useLocation } from 'react-router-dom';

const DeveloperTerms = () => {
  const location = useLocation();
  const state = location.state;
  useEffect(() => {
    if (state && state !== null) {
      window.scrollTo(0, 0);
    }
  }, [state]);

  var scrollPosition = localStorage.getItem('scrollDT');
  if (scrollPosition !== null) {
    window.scrollTo(0, parseInt(scrollPosition));
  }
  return (
    <>
      <LegalSideLayout>
        <DeveloperTermsContent />
      </LegalSideLayout>
    </>
  );
};

export default DeveloperTerms;
