import React, { useEffect, useState } from 'react';
import BulletPoints from '../BulletPoints';
import TitleWithText from '../TitleWithText';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CommonForPrivacy from '../Privacy/CommonForPrivacy';
import CommonPrivacyWithBullets from '../Privacy/CommonPrivacyWithBullets';

const PrivacyPolicyContent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [tableOfContent,] = useState([
    { id: 'WhatInformation', name: t('WhatInformation') },
    { id: 'HowUseData', name: t('HowUseData') },
    { id: 'HowShareData', name: t('HowShareData') },
    { id: 'HowTransferData', name: t('HowTransferData') },
    { id: 'HowStoreData', name: t('HowStoreData') },
    { id: 'CookiesSimilar', name: t('CookiesSimilar') },
    { id: 'YourRightsChoices', name: t('YourRightsChoices') },
    { id: 'CaliforniaRights', name: t('CaliforniaRights') },
    { id: 'DataOfficer', name: t('DataOfficer') },
    { id: 'OtherImportantInfo', name: t('OtherImportantInfo') },
  ]);
  const [privacyData, setPrivacyData] = useState({
    InfoCollectProcess: [
      {
        id: 'a',
        name: t('Collect_a'),
        text: [t('Collect_a_T1')],
        subText: [
          {
            name: t('Collect_a_T_1'),
            text: [t('Collect_a_T_1_S1'), t('Collect_a_T_1_S2')],
          },
          {
            name: t('Collect_a_T_2'),
            text: [t('Collect_a_T_2_S1')],
          },
          {
            name: t('Collect_a_T_3'),
            text: [t('Collect_a_T_3_S1')],
          },
          {
            name: t('Collect_a_T_4'),
            text: [t('Collect_a_T_4_S1'), t('Collect_a_T_4_S2')],
          },
          {
            name: t('Collect_a_T_5'),
            text: [t('Collect_a_T_5_S1')],
          },
        ],
      },
      {
        id: 'b',
        name: t('Collect_b'),
        text: [t('Collect_b_T1'), t('Collect_b_T2'), t('Collect_b_T3')],
      },
      {
        id: 'c',
        name: t('Collect_c'),
        subText: [
          {
            name: t('Collect_c_T_1'),
            text: [t('Collect_c_T_1_S1'), t('Collect_c_T_1_S2')],
          },
          {
            name: t('Collect_c_T_2'),
            text: [t('Collect_c_T_2_S1')],
          },
          {
            name: t('Collect_c_T_3'),
            text: [t('Collect_c_T_3_S1'), t('Collect_c_T_3_S2')],
          },
          {
            name: t('Collect_c_T_4'),
            text: [
              t('Collect_c_T_4_S1'),
              t('Collect_c_T_4_S2'),
              t('Collect_c_T_4_S3'),
            ],
          },
          {
            name: t('Collect_c_T_5'),
            text: [t('Collect_c_T_5_S1')],
          },
          {
            name: t('Collect_c_T_6'),
            text: [t('Collect_c_T_6_S1')],
          },
          {
            name: t('Collect_c_T_7'),
            text: [t('Collect_c_T_7_S1')],
          },
          {
            name: t('Collect_c_T_8'),
            text: [t('Collect_c_T_8_S1'), t('Collect_c_T_8_S2')],
          },
        ],
      },
      {
        id: 'd',
        name: t('Collect_d'),
        subText: [
          {
            name: t('Collect_d_T_1'),
            text: [t('Collect_d_T_1_S1'), t('Collect_d_T_1_S2')],
          },
          {
            name: t('Collect_d_T_2'),
            text: [t('Collect_d_T_2_S1')],
          },
          {
            name: t('Collect_d_T_3'),
            text: [t('Collect_d_T_3_S1')],
          },
          {
            name: t('Collect_d_T_4'),
            text: [t('Collect_d_T_4_S1'), t('Collect_d_T_4_S2')],
            bulletPoints: [t('CollectT1'), t('CollectT2'), t('CollectT3')],
          },
          {
            name: t('Collect_d_T_5'),
            text: [t('Collect_d_T_5_S1'), t('Collect_d_T_5_S2')],
          },
          {
            name: t('Collect_d_T_6'),
            text: [t('Collect_d_T_6_S1')],
          },
        ],
      },
    ],
    UseOfPersonalData: [
      {
        id: 'a',
        name: t('Personal_a'),
        text: [t('Personal_a_T1'), t('Personal_a_T2'), t('Personal_a_T2')],
      },
      {
        id: 'b',
        name: t('Personal_b'),
        text: [t('Personal_b_T1')],
      },
      {
        id: 'c',
        name: t('Personal_c'),
        text: [t('Personal_c_T1'), t('Personal_c_T2'), t('Personal_c_T3')],
      },
      {
        id: 'd',
        name: t('Personal_d'),
        text: [t('Personal_d_T1'), t('Personal_d_T2')],
      },
      {
        id: 'e',
        name: t('Personal_e'),
        text: [t('Personal_e_T1'), t('Personal_e_T2'), t('Personal_e_T2')],
      },
      {
        id: 'f',
        name: t('Personal_f'),
        text: [t('Personal_f_T1')],
      },
      {
        id: 'g',
        name: t('Personal_g'),
        text: [t('Personal_g_T1'), t('Personal_g_T2'), t('Personal_g_T3')],
      },
    ],
    ShareOfData: [
      {
        id: 'a',
        name: t('Share_a'),
        text: [t('Share_a_T1')],
      },
      {
        id: 'b',
        name: t('Share_b'),
        text: [t('Share_b_T1'), t('Share_b_T2')],
      },
      {
        id: 'c',
        name: t('Share_c'),
        text: [t('Share_c_T1')],
      },
      {
        id: 'd',
        name: t('Share_d'),
        text: [t('Share_d_T1')],
      },
      {
        id: 'e',
        name: t('Share_e'),
        text: [t('Share_e_T1')],
      },
    ],
    TransferData: [
      {
        id: 'a',
        name: t('Transfer_a'),
        text: [t('Transfer_a_T1'), t('Transfer_a_T2')],
      },
      {
        id: 'b',
        name: t('Transfer_b'),
        text: [t('Transfer_b_T1')],
      },
      {
        id: 'c',
        name: t('Transfer_c'),
        text: [t('Transfer_c_T1')],
      },
    ],
    StoreData: [
      {
        id: 'a',
        name: t('Store_a'),
        text: [t('Store_a_T1')],
      },
      {
        id: 'b',
        name: t('Store_b'),
        text: [t('Store_b_T1')],
      },
    ],
    RightsAndChoices: [
      {
        id: 'a',
        name: t('rights_a'),
        text: t('rights_a_T1'),
        points: [
          t('rights_a_1'),
          t('rights_a_2'),
          t('rights_a_3'),
          t('rights_a_4'),
        ],
        textBottom: [t('rights_a_T2')],
      },
      {
        id: 'b',
        name: t('rights_b'),
        text: t('rights_b_T1'),
      },
      {
        id: 'c',
        name: t('rights_c'),
        text: t('rights_c_T1'),
        points: [t('rights_c_1'), t('rights_c_2'), t('rights_c_3')],
        textBottom: [t('rights_c_T2')],
      },
      {
        id: 'd',
        name: t('rights_d'),
        text: t('rights_d_T1'),
      },
    ],
    CaliforniaRights: [
      {
        id: 'a',
        name: t('california_a'),
        text: t('california_a_T1'),
      },
      {
        id: 'b',
        name: t('california_b'),
        text: t('california_b_T1'),
      },
      {
        id: 'c',
        name: t('california_c'),
        text: t('california_c_T1'),
      },
      {
        id: 'd',
        name: t('california_d'),
        text: t('california_d_T1'),
        points: [t('rights_d_1'), t('rights_d_2')],
      },
    ],
    PrivacyInfo: [
      {
        id: 'a',
        name: t('privacyInfo_a'),
        text: t('privacyInfo_a_T1'),
      },
      {
        id: 'b',
        name: t('privacyInfo_b'),
        text: t('privacyInfo_b_T1'),
      },
      {
        id: 'c',
        name: t('privacyInfo_c'),
        text: t('privacyInfo_c_T1'),
      },
    ],
  });
  // useEffect(() => {
  //   setTableOfContent(getTranslatedList(t, 'tableOfContent'));
  //   setPrivacyData((prevData) => ({
  //     ...prevData,
  //     InfoCollectProcess: getTranslatedList(t, 'collect_proccess'),
  //     UseOfPersonalData: getTranslatedList(t, 'use_of_personal'),
  //     ShareOfData: getTranslatedList(t, 'share_of_data'),
  //     TransferData: getTranslatedList(t, 'transfer_data'),
  //     StoreData: getTranslatedList(t, 'store_data'),
  //     RightsAndChoices: getTranslatedList(t, 'rights_choices'),
  //     CaliforniaRights: getTranslatedList(t, 'california_rights'),
  //     PrivacyInfo: getTranslatedList(t, 'privacy_info'),
  //   }));
  // }, [t]);
  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleOnClick = (event) => {
    let element = event.target;
    while (element != null && !element.classList.contains('underlineSpan')) {
      element = element.parentElement;
    }

    if (element && element.dataset.url) {
      if (element.dataset.url.includes('https://')) {
        window.open(element.dataset.url, '_blank');
      } else {
        navigate(element.dataset.url, { state: 'send' });
        localStorage.setItem('scrollPP', window.scrollY);
        if (element.dataset.url === '/') {
          setTimeout(() => {
            const element = document.getElementById('ContactId');

            if (element) {
              const elementPosition =
                element.getBoundingClientRect().top + window.scrollY;
              const offsetPosition = elementPosition - 80;
              window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
              });
            }
          }, 500);
        }
      }
    }
  };
  return (
    <div className="">
      <p className="Text60 text-PrimaryT">{t('PrivacyPolicy')}</p>
      <p className="mt-5 Text24Legal text-TernaryT max_sm:text-textNormal">
        {t('LastModifyDate')}
      </p>
      <div className="mt-[30px] flex flex-col gap-4">
        <p className="Text16L">{t('Privacy_T1')}</p>
        {[t('Privacy_T1_P1'), t('Privacy_T1_P2'), t('Privacy_T1_P3')].map(
          (points, index) => (
            <BulletPoints data={points} key={index} pl="20px" />
          )
        )}
        <p className="Text16L">{t('Privacy_T2')}</p>
        <p
          className="Text16L"
          dangerouslySetInnerHTML={{ __html: t('Privacy_T3') }}
          onClick={handleOnClick}
        ></p>
        <p
          className="Text16L"
          dangerouslySetInnerHTML={{ __html: t('Privacy_T4') }}
          onClick={handleOnClick}
        ></p>
        <p className="Text16L">{t('Privacy_T5')}</p>
        {[t('Privacy_T5_P1'), t('Privacy_T5_P2')].map((points, index1) => (
          <BulletPoints
            data={points}
            key={index1}
            pl="20px"
            handleOnClick={handleOnClick}
          />
        ))}
      </div>
      {/* TABLE OF CONTENT */}
      <div className="mt-[30px] flex flex-col gap-3">
        <div className="flex flex-col gap-4">
          {tableOfContent.map((list1, index) => (
            <div className="flex gap-2" key={index}>
              <p className="w-4 Text16L text-PrimaryT text-textNormal font-medium max_sm:text-textSmall">
                {index + 1}.
              </p>
              <p
                className="Text16L cursor-pointer text-PrimaryT underline text-textNormal font-medium max_sm:text-textSmall break-words w-[calc(100%-22px)]"
                onClick={() => handleClickScroll(list1.id)}
              >
                {list1.name}
              </p>
            </div>
          ))}
        </div>
      </div>
      {/* 1. We Collect and Process */}
      <CommonForPrivacy
        title={t('WhatInformation')}
        count={1}
        data={privacyData.InfoCollectProcess}
        id="WhatInformation"
        handleOnClick={handleOnClick}
      />
      {/* 2. HowUseData */}
      <CommonForPrivacy
        title={t('HowUseData')}
        count={2}
        data={privacyData.UseOfPersonalData}
        id="HowUseData"
        handleOnClick={handleOnClick}
      />
      {/* 3 . HowShareData */}
      <CommonForPrivacy
        title={t('HowShareData')}
        count={3}
        data={privacyData.ShareOfData}
        id="HowShareData"
      />
      <CommonForPrivacy
        title={t('HowTransferData')}
        count={4}
        data={privacyData.TransferData}
        id="HowTransferData"
        handleOnClick={handleOnClick}
      />
      <CommonForPrivacy
        title={t('HowStoreData')}
        count={5}
        data={privacyData.StoreData}
        id="HowStoreData"
        handleOnClick={handleOnClick}
      />
      <TitleWithText title={t('CookiesSimilar')} count={6} id="CookiesSimilar">
        <p
          className="Text16L mt-4"
          dangerouslySetInnerHTML={{ __html: t('CookiesSimilarT') }}
          onClick={handleOnClick}
        ></p>
      </TitleWithText>

      <CommonPrivacyWithBullets
        title={t('YourRightsChoices')}
        count={7}
        data={privacyData.RightsAndChoices}
        id="YourRightsChoices"
        handleOnClick={handleOnClick}
      />
      <CommonPrivacyWithBullets
        title={t('CaliforniaRights')}
        count={8}
        data={privacyData.CaliforniaRights}
        id="CaliforniaRights"
      />
      <TitleWithText
        title={t('DataOfficer')}
        count={9}
        text={[t('DataOfficerT')]}
        id="DataOfficer"
      >
        <div className="flex flex-col gap-4 mt-4">
          {[t('EmailPrivacy'), t('PostalAdd')].map((points, index5) => (
            <BulletPoints
              data={points}
              key={index5}
              pl="20px"
              background="#747474"
            />
          ))}
        </div>
      </TitleWithText>
      <CommonPrivacyWithBullets
        id="OtherImportantInfo"
        title={t('OtherImportantInfo')}
        count={10}
        data={privacyData.PrivacyInfo}
        handleOnClick={handleOnClick}
      />
    </div>
  );
};
// const getTranslatedList = (t, key) => {
//   const lists = {
//     tableOfContent: [
//       { id: 'WhatInformation', name: t('WhatInformation') },
//       { id: 'HowUseData', name: t('HowUseData') },
//       { id: 'HowShareData', name: t('HowShareData') },
//       { id: 'HowTransferData', name: t('HowTransferData') },
//       { id: 'HowStoreData', name: t('HowStoreData') },
//       { id: 'CookiesSimilar', name: t('CookiesSimilar') },
//       { id: 'YourRightsChoices', name: t('YourRightsChoices') },
//       { id: 'CaliforniaRights', name: t('CaliforniaRights') },
//       { id: 'DataOfficer', name: t('DataOfficer') },
//       { id: 'OtherImportantInfo', name: t('OtherImportantInfo') },
//     ],
//     collect_proccess: [
//       {
//         id: 'a',
//         name: t('Collect_a'),
//         text: [t('Collect_a_T1')],
//         subText: [
//           {
//             name: t('Collect_a_T_1'),
//             text: [t('Collect_a_T_1_S1'), t('Collect_a_T_1_S2')],
//           },
//           {
//             name: t('Collect_a_T_2'),
//             text: [t('Collect_a_T_2_S1')],
//           },
//           {
//             name: t('Collect_a_T_3'),
//             text: [t('Collect_a_T_3_S1')],
//           },
//           {
//             name: t('Collect_a_T_4'),
//             text: [t('Collect_a_T_4_S1'), t('Collect_a_T_4_S2')],
//           },
//           {
//             name: t('Collect_a_T_5'),
//             text: [t('Collect_a_T_5_S1')],
//           },
//         ],
//       },
//       {
//         id: 'b',
//         name: t('Collect_b'),
//         text: [t('Collect_b_T1'), t('Collect_b_T2'), t('Collect_b_T3')],
//       },
//       {
//         id: 'c',
//         name: t('Collect_c'),
//         subText: [
//           {
//             name: t('Collect_c_T_1'),
//             text: [t('Collect_c_T_1_S1'), t('Collect_c_T_1_S2')],
//           },
//           {
//             name: t('Collect_c_T_2'),
//             text: [t('Collect_c_T_2_S1')],
//           },
//           {
//             name: t('Collect_c_T_3'),
//             text: [t('Collect_c_T_3_S1'), t('Collect_c_T_3_S2')],
//           },
//           {
//             name: t('Collect_c_T_4'),
//             text: [
//               t('Collect_c_T_4_S1'),
//               t('Collect_c_T_4_S2'),
//               t('Collect_c_T_4_S3'),
//             ],
//           },
//           {
//             name: t('Collect_c_T_5'),
//             text: [t('Collect_c_T_5_S1')],
//           },
//           {
//             name: t('Collect_c_T_6'),
//             text: [t('Collect_c_T_6_S1')],
//           },
//           {
//             name: t('Collect_c_T_7'),
//             text: [t('Collect_c_T_7_S1')],
//           },
//           {
//             name: t('Collect_c_T_8'),
//             text: [t('Collect_c_T_8_S1'), t('Collect_c_T_8_S2')],
//           },
//         ],
//       },
//       {
//         id: 'd',
//         name: t('Collect_d'),
//         subText: [
//           {
//             name: t('Collect_d_T_1'),
//             text: [t('Collect_d_T_1_S1'), t('Collect_d_T_1_S2')],
//           },
//           {
//             name: t('Collect_d_T_2'),
//             text: [t('Collect_d_T_2_S1')],
//           },
//           {
//             name: t('Collect_d_T_3'),
//             text: [t('Collect_d_T_3_S1')],
//           },
//           {
//             name: t('Collect_d_T_4'),
//             text: [t('Collect_d_T_4_S1'), t('Collect_d_T_4_S2')],
//             bulletPoints: [t('CollectT1'), t('CollectT2'), t('CollectT3')],
//           },
//           {
//             name: t('Collect_d_T_5'),
//             text: [t('Collect_d_T_5_S1'), t('Collect_d_T_5_S2')],
//           },
//           {
//             name: t('Collect_d_T_6'),
//             text: [t('Collect_d_T_6_S1')],
//           },
//         ],
//       },
//     ],
//     use_of_personal: [
//       {
//         id: 'a',
//         name: t('Personal_a'),
//         text: [t('Personal_a_T1'), t('Personal_a_T2'), t('Personal_a_T2')],
//       },
//       {
//         id: 'b',
//         name: t('Personal_b'),
//         text: [t('Personal_b_T1')],
//       },
//       {
//         id: 'c',
//         name: t('Personal_c'),
//         text: [t('Personal_c_T1'), t('Personal_c_T2'), t('Personal_c_T3')],
//       },
//       {
//         id: 'd',
//         name: t('Personal_d'),
//         text: [t('Personal_d_T1'), t('Personal_d_T2')],
//       },
//       {
//         id: 'e',
//         name: t('Personal_e'),
//         text: [t('Personal_e_T1'), t('Personal_e_T2'), t('Personal_e_T2')],
//       },
//       {
//         id: 'f',
//         name: t('Personal_f'),
//         text: [t('Personal_f_T1')],
//       },
//       {
//         id: 'g',
//         name: t('Personal_g'),
//         text: [t('Personal_g_T1'), t('Personal_g_T2'), t('Personal_g_T3')],
//       },
//     ],
//     share_of_data: [
//       {
//         id: 'a',
//         name: t('Share_a'),
//         text: [t('Share_a_T1')],
//       },
//       {
//         id: 'b',
//         name: t('Share_b'),
//         text: [t('Share_b_T1'), t('Share_b_T2')],
//       },
//       {
//         id: 'c',
//         name: t('Share_c'),
//         text: [t('Share_c_T1')],
//       },
//       {
//         id: 'd',
//         name: t('Share_d'),
//         text: [t('Share_d_T1')],
//       },
//       {
//         id: 'e',
//         name: t('Share_e'),
//         text: [t('Share_e_T1')],
//       },
//     ],
//     store_data: [
//       {
//         id: 'a',
//         name: t('Store_a'),
//         text: [t('Store_a_T1')],
//       },
//       {
//         id: 'b',
//         name: t('Store_b'),
//         text: [t('Store_b_T1')],
//       },
//     ],
//     transfer_data: [
//       {
//         id: 'a',
//         name: t('Transfer_a'),
//         text: [t('Transfer_a_T1'), t('Transfer_a_T2')],
//       },
//       {
//         id: 'b',
//         name: t('Transfer_b'),
//         text: [t('Transfer_b_T1')],
//       },
//       {
//         id: 'c',
//         name: t('Transfer_c'),
//         text: [t('Transfer_c_T1')],
//       },
//     ],
//     rights_choices: [
//       {
//         id: 'a',
//         name: t('rights_a'),
//         text: t('rights_a_T1'),
//         points: [
//           t('rights_a_1'),
//           t('rights_a_2'),
//           t('rights_a_3'),
//           t('rights_a_4'),
//         ],
//         textBottom: [t('rights_a_T2')],
//       },
//       {
//         id: 'b',
//         name: t('rights_b'),
//         text: t('rights_b_T1'),
//       },
//       {
//         id: 'c',
//         name: t('rights_c'),
//         text: t('rights_c_T1'),
//         points: [t('rights_c_1'), t('rights_c_2'), t('rights_c_3')],
//         textBottom: [t('rights_c_T2')],
//       },
//       {
//         id: 'd',
//         name: t('rights_d'),
//         text: t('rights_d_T1'),
//       },
//     ],
//     california_rights: [
//       {
//         id: 'a',
//         name: t('california_a'),
//         text: t('california_a_T1'),
//       },
//       {
//         id: 'b',
//         name: t('california_b'),
//         text: t('california_b_T1'),
//       },
//       {
//         id: 'c',
//         name: t('california_c'),
//         text: t('california_c_T1'),
//       },
//       {
//         id: 'd',
//         name: t('california_d'),
//         text: t('california_d_T1'),
//         points: [t('rights_d_1'), t('rights_d_2')],
//       },
//     ],
//     privacy_info: [
//       {
//         id: 'a',
//         name: t('privacyInfo_a'),
//         text: t('privacyInfo_a_T1'),
//       },
//       {
//         id: 'b',
//         name: t('privacyInfo_b'),
//         text: t('privacyInfo_b_T1'),
//       },
//       {
//         id: 'c',
//         name: t('privacyInfo_c'),
//         text: t('privacyInfo_c_T1'),
//       },
//     ],
//   };

//   return lists[key];
// };

export default PrivacyPolicyContent;
