import React, { useState } from 'react';
import CommonSection from '../Common/CommonSection';
// import Dashboard from '../../assets/images/Homepage/UnifiedDashboard.svg'
// import Campaigns from '../../assets/images/Homepage/TailoredCampaigns.svg'
// import Tracking from '../../assets/images/Homepage/ConversionTracking.svg'
// import Automation from '../../assets/images/Homepage/WorkflowAutomation.svg'
// import MobileAccess from '../../assets/images/Homepage/MobileAccess.svg';
import BgImage from '../../assets/images/Homepage/DottedFrame.svg';
import { ButtonFilled } from '../Button';

const EngageSmart = ({ handleClick }) => {
  const [openIndex, setopenIndex] = useState(0);
  const [data] = useState([
    {
      title: 'Unified Dashboard',
      text: 'View key metrics and performance indicators at a glance.',
      image: 'https://images.dcdial.com/epifony.com/UnifiedDashboard.svg',
    },
    {
      title: 'Tailored Campaigns',
      text: 'Customize communication strategies based on detailed contact insights.',
      image: 'https://images.dcdial.com/epifony.com/TailoredCampaigns.svg',
    },
    {
      title: 'Conversion Tracking',
      text: 'Track engagement levels to focus efforts where they count most.',
      image: 'https://images.dcdial.com/epifony.com/ConversionTracking.svg',
    },
    {
      title: 'Workflow Automation',
      text: ' Automate repetitive tasks like sending follow-up emails, updating records, and more.',
      image: 'https://images.dcdial.com/epifony.com/WorkflowAutomation.svg',
    },
    {
      title: 'Mobile Access',
      text: 'Manage your contacts on-the-go with our fully optimized mobile app.',
      image: 'https://images.dcdial.com/epifony.com/MobileAccess.svg',
    },
  ]);
  return (
    <CommonSection>
      <div className="flex flex-col gap-10 max_sm:gap-5">
        <h2 className="Text60 text-center">Engage smarter, not harder</h2>
        <p className="Text20 text-center">
          Make every client feel like your only client. Epifony helps you send
          the right message at the right time, leveraging data-driven insights
          to personalize your communications and build stronger, more meaningful
          relationships.
        </p>
        <div className="mt-5 relative overflow-hidden border-[1px] border-StrokePrimary rounded-2xl flex py-[80px] pl-[80px] max_md:py-10 max_md:px-10 max_sm:p-6 justify-between max_xl:gap-5">
          <div className="absolute top-0 left-0 -z-10">
            <img src={BgImage} alt="BgImage" />
          </div>
          <div className="max-w-[400px] max_xl:max-w-[340px] max_md:max-w-full">
            {data?.map((list, index) => {
              return (
                <React.Fragment key={index + 1}>
                  <div
                    className={`${
                      index === 0 ? '' : 'pt-10 max_xl:pt-[30px] max_md:pt-5'
                    } pb-5 max_md:pb-5  cursor-pointer ${
                      openIndex === index
                        ? 'border-b-[1px] border-StrokeSecondary'
                        : ''
                    }`}
                    onClick={() => {
                      if (openIndex === index) {
                        // setopenIndex(-1);
                      } else {
                        setopenIndex(index);
                      }
                    }}
                  >
                    <div
                      className={`Text24 ${
                        openIndex === index ? 'text-PrimaryT' : 'text-TernaryT'
                      }`}
                    >
                      {list.title}
                    </div>
                    <div
                      className={`answerEngage ${
                        openIndex === index ? 'open' : ''
                      }`}
                    >
                      <div className={`mt-3 Text20`}>{list.text}</div>
                      <div className="hidden max_md:block mt-[30px]">
                        <img
                          src={list?.image}
                          alt={list.title}
                          style={{
                            boxShadow:
                              '-8px 8px 40px 0px rgba(223, 228, 236, 0.30)',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>

          <div className="max_md:hidden mr-[-12px]">
            <img
              src={data[openIndex]?.image}
              alt={data[openIndex]?.title}
              className="rounded-[16px] max_xl:rounded-md"
              style={{
                boxShadow: '-8px 8px 40px 0px rgba(223, 228, 236, 0.30)',
              }}
            />
          </div>
        </div>

        <div className="w-max m-auto mt-5">
          <ButtonFilled
            title="Book a demo"
            onClick={() => handleClick('ContactId')}
          />
        </div>
      </div>
    </CommonSection>
  );
};

export default EngageSmart;
