import React from 'react'

const CommonSection = ({ children,isCustomPadding }) => {
  return (
    <div
      className={`m-auto max-w-[1240px] max_xl:max-w-[980px] max_md:px-10 max_sm:px-5 ${
        isCustomPadding ? 'py-[50px] max_md:py-10 max_sm:py-5' : 'py-[100px] max_md:py-[60px] max_sm:py-[30px]'
      }`}
    >
      {children}
    </div>
  );
};

export default CommonSection