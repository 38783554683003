import React from 'react';
import CommonSection from '../Common/CommonSection';
import { ExploreArrow } from '../../assets/icons/icon';
import {
  ClickupIcon,
  GmailIcon,
  GooglesheetIcon,
  HubspotIcon,
  MailChipIcon,
  MondayIcon,
  SalesforceIcon,
  StripeIcon,
  ZapierIcon,
} from '../../assets/icons/IntegrationIcon';
import { useWindowSize } from '../../Hooks/useWindowSize';

const IntegrateWorkflow = ({ handleClick }) => {
  const { width: windowWidth } = useWindowSize();
  const isTabView = windowWidth < 800;
  const isMobileView = windowWidth < 600;
  return (
    <CommonSection>
      <div>
        <h2 className="Text60 text-center max-w-[940px] m-auto">
          Integrations that power your workflow
        </h2>
        <p className="Text20 text-center mt-10 max_md:mt-5">
          Epifony integrates effortlessly with your existing tools—from
          marketing automation to sales platforms—creating a cohesive ecosystem
          that propels productivity.
        </p>
        <div className="w-max m-auto mt-[60px] max_md:mt-5">
          <div className="flex gap-[10px] items-center py-3 px-4 bg-ButtonPrimary hover:bg-ButtonHover cursor-pointer rounded-[12px] max_sm:rounded-md">
            <p
              className="text-white text-textNormal font-UrbanistFont font-semibold max_sm:text-textSmall"
              onClick={() => handleClick('ContactId')}
            >
              Explore our integrations
            </p>
            <ExploreArrow
              style={{
                width: isMobileView ? 20 : 25,
                height: isMobileView ? 20 : 25,
              }}
            />
          </div>
        </div>

        {/* ANIMATION */}
        <div className="mt-[60px] max_sm:mt-10">
          <div className="flex justify-between max-w-[820px] max_md:max-w-[740px] max_800:max-w-[620px] max_600:max-w-[330px] max_350:max-w-[280px] m-auto">
            <div className="integration-shadow bubble1">
              <HubspotIcon
                style={{
                  width: isMobileView ? 37 : isTabView ? 50 : 60,
                  height: isMobileView ? 37 : isTabView ? 50 : 60,
                }}
              />
            </div>
            <div className="integration-shadow bubble2">
              <SalesforceIcon
                style={{
                  width: isMobileView ? 37 : isTabView ? 50 : 60,
                  height: isMobileView ? 37 : isTabView ? 50 : 60,
                }}
              />
            </div>
            <div className="integration-shadow bubble3">
              <ClickupIcon
                style={{
                  width: isMobileView ? 37 : isTabView ? 50 : 60,
                  height: isMobileView ? 37 : isTabView ? 50 : 60,
                }}
              />
            </div>
            <div className="integration-shadow bubble4">
              <MondayIcon
                style={{
                  width: isMobileView ? 37 : isTabView ? 50 : 60,
                  height: isMobileView ? 37 : isTabView ? 50 : 60,
                }}
              />
            </div>
            <div className="integration-shadow max_600:hidden bubble5">
              <GooglesheetIcon
                style={{
                  width: isTabView ? 50 : 60,
                  height: isTabView ? 50 : 60,
                }}
              />
            </div>
          </div>

          <div className="flex justify-between max-w-[637px] max_md:max-w-[600px] max_800:max-w-[480px] max_600:max-w-[248px] max_350:max-w-[200px] m-auto mt-5">
            <div className="integration-shadow bubble5">
              <MailChipIcon
                style={{
                  width: isMobileView ? 37 : isTabView ? 50 : 60,
                  height: isMobileView ? 37 : isTabView ? 50 : 60,
                }}
              />
            </div>
            <div className="integration-shadow bubble4">
              <StripeIcon
                style={{
                  width: isMobileView ? 37 : isTabView ? 50 : 60,
                  height: isMobileView ? 37 : isTabView ? 50 : 60,
                }}
              />
            </div>
            <div className="integration-shadow bubble1">
              <ZapierIcon
                style={{
                  width: isMobileView ? 37 : isTabView ? 50 : 60,
                  height: isMobileView ? 37 : isTabView ? 50 : 60,
                }}
              />
            </div>
            <div className="integration-shadow max_600:hidden bubble2">
              <GmailIcon
                style={{
                  width: isTabView ? 50 : 60,
                  height: isTabView ? 50 : 60,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </CommonSection>
  );
};

export default IntegrateWorkflow;
