import React from 'react';
import CommonSection from './Common/CommonSection';
import {
  EFLogo,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  YoutubeIcon,
} from '../assets/icons/icon';
import { useWindowSize } from '../Hooks/useWindowSize';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 600;
  const navigate = useNavigate()
  const handleClick = (to) => {
    navigate(to);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 50);
  };
  return (
    <div className="max-w-[1920px] m-auto bg-BgSecondary">
      <CommonSection isCustomPadding="50px">
        <div className="flex flex-col gap-10">
          <div>
            <div>
              <EFLogo />
            </div>
            <div className="flex gap-3 mt-10 max_md:mt-[30px] max_sm:mt-6">
              <div className="cursor-pointer">
                <a
                  href="https://www.facebook.com/epifony"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FacebookIcon
                    style={{
                      width: isMobileView ? 24 : 36,
                      height: isMobileView ? 24 : 36,
                    }}
                  />
                </a>
              </div>
              <div className="cursor-pointer">
                <a
                  href="https://x.com/epifonyapp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TwitterIcon
                    style={{
                      width: isMobileView ? 24 : 36,
                      height: isMobileView ? 24 : 36,
                    }}
                  />
                </a>
              </div>
              <div className="cursor-pointer">
                <a
                  href="https://www.instagram.com/epifonyapp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramIcon
                    style={{
                      width: isMobileView ? 24 : 36,
                      height: isMobileView ? 24 : 36,
                    }}
                  />
                </a>
              </div>
              <div className="cursor-pointer">
                <a
                  href="https://www.youtube.com/@Epifony"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <YoutubeIcon
                    style={{
                      width: isMobileView ? 24 : 36,
                      height: isMobileView ? 24 : 36,
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="w-full h-[1px] bg-StrokePrimary"></div>
          <div className="flex justify-between max_md:flex-row-reverse max_md:justify-end max_md:gap-4 max_sm:flex-col-reverse">
            <p className="Text16Normal text-SecondaryT">
              {' '}
              © {new Date().getFullYear()} Epifony LLC | All Rights Reserved.
            </p>
            <div className="Text16Normal text-SecondaryT flex gap-4 items-center">
              <p
                className="cursor-pointer"
                onClick={() => handleClick('/legal/privacy-policy')}
              >
                Privacy Policy
              </p>
              <p className="w-[1px] h-[18px] bg-SecondaryT"></p>
              <p
                className="cursor-pointer"
                onClick={() => handleClick('/legal')}
              >
                Legal
              </p>
              <p className="w-[1px] h-[18px] bg-SecondaryT hidden max_md:block max_sm:hidden"></p>
            </div>
          </div>
        </div>
      </CommonSection>
    </div>
  );
};

export default Footer;
