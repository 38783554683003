import React from 'react';
import CommonLayout from './Components/CommonLayout';
import HomePage from './Pages/HomePage';
import { Route, Routes } from 'react-router-dom';
import PageNotFound from './Pages/PageNotFound';
import i18n from './i18n';
import LegalPage from './Pages/Legal/LegalPage';
import TermsOfService from './Pages/Legal/TermsOfService';
import SpecificProductService from './Pages/Legal/SpecificProductService';
import DataProcessing from './Pages/Legal/DataProcessing';
import AcceptableUsePolicy from './Pages/Legal/AcceptableUsePolicy';
import DeveloperTerms from './Pages/Legal/DeveloperTerms';
import DeveloperPolicy from './Pages/Legal/DeveloperPolicy';
import WebTermsOfUse from './Pages/Legal/WebTermsOfUse';
import WebAccessibility from './Pages/Legal/WebAccessibility';
import PrivacyPolicy from './Pages/Legal/PrivacyPolicy';
import CookiePolicy from './Pages/Legal/CookiePolicy';
import DigitalServicePolicy from './Pages/Legal/DigitalServicePolicy';
import SignInPage from './Pages/Auth/SignInPage';
function App() {
  return (
    <Routes>
      <Route element={<CommonLayout />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/legal" element={<LegalPage />} />

        {/* ALL LEGAL PAGES */}
        <Route path="/legal/terms-of-service" element={<TermsOfService />} />
        <Route
          path="/legal/product-specific-terms"
          element={<SpecificProductService />}
        />
        <Route path="/legal/dpa" element={<DataProcessing />} />
        <Route path="/legal/acceptable-use" element={<AcceptableUsePolicy />} />
        <Route path="/legal/developer-terms" element={<DeveloperTerms />} />
        <Route path="/legal/developer-policy" element={<DeveloperPolicy />} />
        <Route path="/legal/website-terms-of-use" element={<WebTermsOfUse />} />
        <Route
          path="/legal/website-accessibility"
          element={<WebAccessibility />}
        />
        <Route path="/legal/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/legal/cookie-policy" element={<CookiePolicy />} />
        <Route
          path="/legal/digital-services-act"
          element={<DigitalServicePolicy />}
        />
      </Route>
      <Route path="*" element={<PageNotFound />} />
      <Route path="/sign-in" element={<SignInPage />} />
    </Routes>
  );
}

export default App;
