import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  ForCustomer,
  ForDevelopers,
  ForEveryone,
  InfoIcon,
} from '../../assets/LegalIcons';
import { useWindowSize } from '../../Hooks/useWindowSize';

const LegalGroup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 500;

  const [legalGroups, setLegalGroups] = useState([]);
  const [handleIndex, setHandleIndex] = useState({
    mainIndex: null,
    subIndex: null,
  });
  useEffect(() => {
    setLegalGroups(getTranslatedList(t));
  }, [t]);

  const handleNavigate = (link) => {
    navigate(link);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 50);
  };
  return (
    <div className="mt-[50px] max_md:mt-5 max_sm:mt-[10px] w-full grid grid-cols-3 gap-[30px] max_xl:gap-5 max_md:grid-cols-2 max_md:gap-[30px] max_768:grid-cols-1 max_768:place-items-center">
      {legalGroups.map((groups, index) => (
        <div
          className="border-[1px] border-StrokePrimary rounded-[20px] p-[30px] max_xl:p-[20px] max_md:p-[30px] bg-white flex flex-col gap-6 w-[360px] max_xl:w-[320px] max_md:w-[336px] max_768:w-[360px] max_sm:w-full max_sm:p-4"
          key={index}
        >
          <div>{groups.icon}</div>
          <p className="Text24 text-PrimaryT">{groups.title}</p>
          <p className="Text20 max_xl:min-h-[180px] max_sm:leading-6 max_md:min-h-max">
            {groups.text}
          </p>
          <div className="w-full h-[1px] bg-StrokePrimary"></div>
          {groups.list.map((list, index1) => (
            <div className="relative flex gap-1" key={index1}>
              <div
                className={`relative cursor-pointer Text20 text-ButtonPrimary ${
                  index === 2 && index1 === 4 ? 'max_350:max-w-[220px]' : ''
                }`}
                onClick={() => handleNavigate(list.link)}
              >
                {list.title}
              </div>
              <div
                className={`${
                  index === 2 && index1 === 4
                    ? 'absolute left-9 top-[30px] max_md:left-28 max_768:left-9 max_sm:left-[254px] max_sm:top-0 max_350:top-6 max_350:left-24'
                    : ''
                }`}
              >
                <div
                  className="cursor-pointer mt-[6px] max_sm:mt-[3px] relative h-max"
                  onMouseEnter={() =>
                    setHandleIndex({ mainIndex: index, subIndex: index1 })
                  }
                  onMouseLeave={() =>
                    setHandleIndex({ mainIndex: null, subIndex: null })
                  }
                >
                  <InfoIcon
                    color={
                      handleIndex.mainIndex === index &&
                      handleIndex.subIndex === index1
                        ? '#E4A810'
                        : '#FDBA12'
                    }
                  />
                  {handleIndex.mainIndex === index &&
                    handleIndex.subIndex === index1 && (
                      <>
                        <div>
                          <div
                            className={`absolute bottom-[30px] bg-BgLight w-[220px] p-[10px] text-[12px] border-[1px] border-ButtonPrimary font-UrbanistFont font-normal text-PrimaryT rounded-[10px] ${isMobileView ? list.right :'right-[-20px]'}`}
                          >
                            {list.text}
                          </div>

                          <div
                            className="absolute right-0 bottom-[25.5px] w-[10px] h-[10px] bg-BgLight border-b-[1px] border-r-[1px] border-ButtonPrimary rotate-45 z-50"
                            // style={{ clipPath: 'polygon(51% 100%, 0 0, 100% 0)' }}
                          ></div>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
const getTranslatedList = (t) => {
  const services = [
    {
      id: 1,
      title: t('ForCustomer'),
      text: t('ForCustomerT'),
      icon: <ForCustomer />,
      list: [
        {
          id: 1,
          title: t('CustomerTerms'),
          text: t('CustomerTermsT'),
          link: '/legal/terms-of-service',
          right: 'right-[-30px]',
        },
        {
          id: 2,
          title: t('ProductTerms'),
          text: t('ProductTermsT'),
          link: '/legal/product-specific-terms',
          right: 'right-[-40px]',
        },
        {
          id: 3,
          title: t('DataProcessing'),
          text: t('DataProcessingT'),
          link: '/legal/dpa',
          right: 'right-[-32px]',
        },
        {
          id: 4,
          title: t('AcceptablePolicy'),
          text: t('AcceptablePolicyT'),
          link: '/legal/acceptable-use',
          right: 'right-[-60px]',
        },
      ],
    },
    {
      id: 2,
      title: t('ForDevelopers'),
      text: t('ForDevelopersT'),
      icon: <ForDevelopers />,
      list: [
        {
          id: 1,
          title: t('DeveloperTerms'),
          text: t('DeveloperTermsT'),
          link: '/legal/developer-terms',
          right: 'right-[-35px]',
        },
        {
          id: 2,
          title: t('DeveloperPolicy'),
          text: t('DeveloperPolicyT'),
          link: '/legal/developer-policy',
          right: 'right-[-35px]',
        },
        {
          id: 3,
          title: t('AcceptablePolicy'),
          text: t('AcceptablePolicyTN'),
          link: '/legal/acceptable-use',
          right: 'right-[-40px]',
        },
      ],
    },
    {
      id: 3,
      title: t('ForEveryone'),
      text: t('ForEveryoneT'),
      icon: <ForEveryone />,
      list: [
        {
          id: 1,
          title: t('WebsiteTerms'),
          text: t('WebsiteTermsT'),
          link: '/legal/website-terms-of-use',
          right: 'right-[-40px]',
        },
        {
          id: 2,
          title: t('WebsiteAccess'),
          text: t('WebsiteAccessT'),
          link: '/legal/website-accessibility',
          right: 'right-[-40px]',
        },
        {
          id: 3,
          title: t('PrivacyPolicy'),
          text: t('PrivacyPolicyT'),
          link: '/legal/privacy-policy',
          right: 'right-[-100px]',
        },
        {
          id: 4,
          title: t('CookiePolicy'),
          text: t('CookiePolicyT'),
          link: '/legal/cookie-policy',
          right: 'right-[-100px]',
        },
        {
          id: 5,
          title: t('EuropeanServices'),
          text: t('EuropeanServicesT'),
          link: '/legal/digital-services-act',
          right: 'right-[-10px] max_350:right-[-90px]',
        },
      ],
    },
  ];

  return services;
};

export default LegalGroup;
