import React, { useEffect } from 'react';
import LegalSideLayout from '../../Components/Legal/LegalSideLayout';
import CookiePolicyContent from '../../Components/Legal/LegalPageContent/CookiePolicyContent';
import { useLocation } from 'react-router-dom';

const CookiePolicy = () => {
  const location = useLocation();
  const state = location.state;
  useEffect(() => {
    if (state && state !== null) {
      window.scrollTo(0, 0);
    }
  }, [state]);

  var scrollPosition = localStorage.getItem('scrollCP');
  if (scrollPosition !== null) {
    window.scrollTo(0, parseInt(scrollPosition));
  }
  return (
    <>
      <LegalSideLayout>
        <CookiePolicyContent />
      </LegalSideLayout>
    </>
  );
};

export default CookiePolicy;
