import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import BulletPoints from '../BulletPoints';

const CookiePolicyContent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [cookieData] = useState([
    {
      title: t('Ques1'),
      text: [t('Ques1_1'), t('Ques1_2')],
    },
    {
      title: t('Ques2'),
      text: [t('Ques2_1')],
    },
    {
      title: t('Ques3'),
      points: [
        t('Ques3_1'),
        t('Ques3_2'),
        t('Ques3_3'),
        t('Ques3_4'),
        t('Ques3_5'),
      ],
    },
    {
      title: t('Ques4'),
      text: [t('Ques4_1'), t('Ques4_2'), t('Ques4_3')],
    },
    {
      title: t('Ques5'),
      text: [t('Ques5_1')],
    },
    {
      title: t('Ques6'),
      text: [t('Ques6_1')],
    },
    {
      title: t('Ques7'),
      text: [t('Ques7_1'), t('Ques7_2')],
    },
    {
      title: t('Ques8'),
      text: [t('Ques8_1')],
    },
    {
      title: t('Ques9'),
      text: [t('Ques9_1')],
    },
    {
      title: t('Ques10'),
      text: [t('Ques10_1')],
    },
  ]);
  // useEffect(() => {
  //   stCookieData(getTranslatedList(t));
  // }, [t]);
  const handleOnClick = (event) => {
    let element = event.target;
    while (element != null && !element.classList.contains('underlineSpan')) {
      element = element.parentElement;
    }

    if (element && element.dataset.url) {
      if (element.dataset.url.includes('http')) {
        window.open(element.dataset.url, '_blank');
      } else {
        navigate(element.dataset.url, { state: 'send' });
        localStorage.setItem('scrollCP', window.scrollY);
      }
    }
  };
  return (
    <div className="">
      <p className="Text60 text-PrimaryT">{t('CookiePolicy')}</p>
      <p className="mt-5 Text24Legal text-TernaryT max_sm:text-textNormal">
        {t('LastModifyDate')}
      </p>
      <p
        className="Text16L mt-10"
        dangerouslySetInnerHTML={{ __html: t('CookieT') }}
        onClick={handleOnClick}
      ></p>

      <div className="mt-[30px] flex flex-col gap-4">
        {cookieData?.map((list, index) => (
          <React.Fragment key={index}>
            <p className="mt-4 Text16L text-[18px] text-SecondaryT font-semibold">
              {list.title}
            </p>
            {list.text && (
              <>
                {list.text.map((innerText, index1) => (
                  <p
                    key={index1}
                    className="Text16L"
                    dangerouslySetInnerHTML={{ __html: innerText }}
                    onClick={handleOnClick}
                  ></p>
                ))}
              </>
            )}
            {list.points && (
              <>
                {list.points.map((list2, index2) => (
                  <BulletPoints data={list2} key={index2} pl="20px" />
                ))}
              </>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
// const getTranslatedList = (t) => {
//   const lists = [
//     {
//       title: t('Ques1'),
//       text: [t('Ques1_1'), t('Ques1_2')],
//     },
//     {
//       title: t('Ques2'),
//       text: [t('Ques2_1')],
//     },
//     {
//       title: t('Ques3'),
//       points: [
//         t('Ques3_1'),
//         t('Ques3_2'),
//         t('Ques3_3'),
//         t('Ques3_4'),
//         t('Ques3_5'),
//       ],
//     },
//     {
//       title: t('Ques4'),
//       text: [t('Ques4_1'), t('Ques4_2'), t('Ques4_3')],
//     },
//     {
//       title: t('Ques5'),
//       text: [t('Ques5_1')],
//     },
//     {
//       title: t('Ques6'),
//       text: [t('Ques6_1')],
//     },
//     {
//       title: t('Ques7'),
//       text: [t('Ques7_1'), t('Ques7_2')],
//     },
//     {
//       title: t('Ques8'),
//       text: [t('Ques8_1')],
//     },
//     {
//       title: t('Ques9'),
//       text: [t('Ques9_1')],
//     },
//     {
//       title: t('Ques10'),
//       text: [t('Ques10_1')],
//     },
//   ];
//   return lists;
// };

export default CookiePolicyContent;
