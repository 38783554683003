import React, { useEffect } from 'react';
import HerosectionBg from '../Components/Homepage/HerosectionBg';
import BannerSection from '../Components/Homepage/BannerSection';
import EngageSmart from '../Components/Homepage/EngageSmart';
import CentralizeManagement from '../Components/Homepage/CentralizeManagement';
import Automation from '../Components/Homepage/Automation';
import TeamSynchronize from '../Components/Homepage/TeamSynchronize';
import IntegrateWorkflow from '../Components/Homepage/IntegrateWorkflow';
import FAQ from '../Components/Homepage/FAQ';
import ContactUs from '../Components/Homepage/ContactUs';
import { useLocation, useNavigate } from 'react-router-dom';
import { HomeEllipse2 } from '../assets/icons/icon';
import { useWindowSize } from '../Hooks/useWindowSize';

const HomePage = () => {
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 600;
  const navigate = useNavigate();
  const location = useLocation();
  
  const handleClickScroll = (id) => {
    if (location.pathname !== '/') {
      navigate('/');
    }
    setTimeout(() => {
      const element = document.getElementById(id);

      if (element) {
        const elementPosition =
          element.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - 80;
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }, 500);
  };

  useEffect(() => {
    localStorage.removeItem('scrollT');
    localStorage.removeItem('scrollS');
    localStorage.removeItem('scrollDPA');
    localStorage.removeItem('scrollA');
    localStorage.removeItem('scrollD');
    localStorage.removeItem('scrollDT');
    localStorage.removeItem('scrollWT');
    localStorage.removeItem('scrollWA');
    localStorage.removeItem('scrollPP');
    localStorage.removeItem('scrollCP');
    localStorage.removeItem('scrollSD');
  }, []);

  
  return (
    <>
      <div className="relative">
        <div className="absolute top-[6%] max_sm:top-[7%] left-[8%] opacity-60 rotate-[-30deg] blur-[250px] max_sm:blur-[240px] -z-10">
          <HomeEllipse2
            style={{
              width: isMobileView ? '220' : '320',
              height: isMobileView ? '310' : '410',
            }}
          />
        </div>
        <div className="w-full ">
          <HerosectionBg>
            <BannerSection handleClick={handleClickScroll} />
          </HerosectionBg>
        </div>
        <EngageSmart handleClick={handleClickScroll} />
        <CentralizeManagement />
        <Automation handleClick={handleClickScroll} />
        <TeamSynchronize handleClick={handleClickScroll} />
        <IntegrateWorkflow handleClick={handleClickScroll} />
        <FAQ />
        <ContactUs />
      </div>
    </>
  );
};

export default HomePage;
