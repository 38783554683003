import React, { useState } from 'react';
import CommonSection from '../Common/CommonSection';
import { CollaborationIcon, ContactManageIcon, DocumentIcon, EmailIntegrationIcon, LeadTrackingIcon, TaskManageIcon } from '../../assets/icons/icon';

const CentralizeManagement = () => {
  const [services] = useState([
    {
      name: 'Contact Management',
      icon: <ContactManageIcon />,
      text: 'Centralize your contacts, with detailed profiles including communication history, social media activity, and key interactions.',
    },
    {
      name: 'Task & Calendar Management',
      icon: <TaskManageIcon />,
      text: 'Schedule meetings, set reminders, and never miss a follow-up with integrated calendars and task lists.',
    },
    {
      name: 'Email Integration & Tracking',
      icon: <EmailIntegrationIcon />,
      text: 'Send and track emails directly within the platform. Get notified when emails are opened or links are clicked.',
    },
    {
      name: 'Lead & Deal Tracking',
      icon: <LeadTrackingIcon />,
      text: 'Manage your sales pipeline with customizable stages and detailed reports on your prospects and ongoing deals.',
    },
    {
      name: 'Document Storage',
      icon: <DocumentIcon />,
      text: 'Easily store, manage, and share important documents and contracts directly from contact profiles.',
    },
    {
      name: 'Collaboration Tools',
      icon: <CollaborationIcon />,
      text: 'Share contacts, notes, and documents seamlessly within your team. ',
    },
  ]);
  return (
    <>
    <CommonSection>
      <div className="flex flex-col gap-[60px] max_sm:gap-10">
        <h2 className="Text60 text-center max-w-[940px] m-auto">
          Centralized management for modern teams 
        </h2>
        <div className="flex flex-wrap justify-center gap-11 max_xl:gap-10 max_sm:gap-5">
          {services.map((service, index) => (
            <div
              className="bg-BgLight w-[280px] rounded-[20px] p-[30px] max_sm:p-5 flex flex-col"
              key={index}
            >
              <div>
                {service.icon}
                </div>
              <p className="mt-6 Text20 font-semibold text-PrimaryT">
                {service.name}
              </p>
              <p className="mt-3 Text16Normal">
                {service.text}
              </p>
            </div>
          ))}
        </div>
      </div>
    </CommonSection>
    </>
  );
};

export default CentralizeManagement;
