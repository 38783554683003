import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Definition from '../Definition';
import CommonAlphabeticComp from '../CommonAlphabeticComp';
import SubProcessorComp from '../DPA/SubProcessorComp';
import CommonHeadText from '../CommonHeadText';
import Annex1 from '../DPA/Annex1';
import Annex2 from '../DPA/Annex2';
import ProvisionsEuropean from '../DPA/ProvisionsEuropean';

const DPAContent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [tableOfContent, setTableOfContent] = useState([
    {
      name: t('Definitions'),
      id: 'D_Definitions',
    },
    {
      name: t('CustomerResponsibilities'),
      id: 'CustomerResponsibilities',
    },
    {
      name: t('AvObligations'),
      id: 'AvObligations',
    },
    {
      name: t('DataSubjectRequests'),
      id: 'DataSubjectRequests',
    },
    {
      name: t('SubProcessors'),
      id: 'SubProcessors',
    },
    {
      name: t('DataTransfers'),
      id: 'DataTransfers',
    },
    {
      name: t('DemonstrationCompliance'),
      id: 'DemonstrationCompliance',
    },
    {
      name: t('AdditionalEuropean'),
      id: 'AdditionalEuropean',
    },
    {
      name: t('AdditionalCalifornia'),
      id: 'AdditionalCalifornia',
    },
    {
      name: t('GeneralProvisions'),
      id: 'GeneralProvisions',
    },
    {
      name: t('PartiesDPA'),
      id: 'PartiesDPA',
    },
  ]);
  const [defList, setDefList] = useState([
    t('Def1Pro'),
    t('Def2Pro'),
    t('Def3Pro'),
    t('Def4Pro'),
    t('Def5Pro'),
    t('Def6Pro'),
    t('Def7Pro'),
    t('Def8Pro'),
    t('Def9Pro'),
    t('Def10Pro'),
    t('Def11Pro'),
    t('Def12Pro'),
    t('Def13Pro'),
    t('Def14Pro'),
    t('Def15Pro'),
    t('Def16Pro'),
    t('Def17Pro'),
    t('Def18Pro'),
    t('Def19Pro'),
    t('Def20Pro'),
  ]);
  const [defItem, setDefItem] = useState({
    responsibility: [
      {
        id: 'a',
        name: t('A_ComplianceLawsT'),
        text: [
          t('ComplianceLawsT1'),
          t('ComplianceLawsT2'),
          t('ComplianceLawsT3'),
          t('ComplianceLawsT4'),
        ],
      },
      {
        id: 'b',
        name: t('B_ControllerInstrT'),
      },
      {
        id: 'c',
        name: t('C_SecurityT'),
      },
    ],
    obligations: [
      {
        id: 'a',
        name: t('A_Obligations'),
      },
      {
        id: 'b',
        name: t('B_Obligations'),
        text: [t('B_ObligationsT1'), t('B_ObligationsT2')],
        moreText: [t('B_ObligationsT3')],
      },
      {
        id: 'c',
        name: t('C_Obligations'),
      },
      {
        id: 'd',
        name: t('D_Obligations'),
      },
      {
        id: 'e',
        name: t('E_Obligations'),
      },
      {
        id: 'f',
        name: t('F_Obligations'),
        moreText: [t('F_T1'), t('F_T2')],
      },
    ],
    Subject_Requests: [
      {
        moreText: [
          t('SubjectRequest1'),
          t('SubjectRequest2'),
          t('SubjectRequest3'),
        ],
      },
    ],
    SubProcessor: [
      {
        text: [t('ProcessorT1'), t('ProcessorT2'), t('ProcessorT3')],
        list: [t('Processor1'), t('Processor2'), t('Processor3')],
      },
    ],
    European_data: [
      {
        id: 'a',
        name: t('A_Europian'),
      },
      {
        id: 'b',
        name: t('B_Europian'),
      },
      {
        id: 'c',
        name: t('C_Europian'),
      },
      {
        id: 'd',
        name: t('D_Europian'),
      },
      {
        id: 'e',
        name: t('E_Europian'),
      },
    ],
    California_data: [
      {
        id: 'a',
        name: t('Cal_A'),
      },
      {
        id: 'b',
        name: t('Cal_B'),
      },
      {
        id: 'c',
        name: t('Cal_C'),
        text: [t('Cal_C_1'), t('Cal_C_2'), t('Cal_C_3')],
      },
      {
        id: 'd',
        name: t('Cal_D'),
      },
      {
        id: 'e',
        name: t('Cal_E'),
      },
      {
        id: 'f',
        name: t('Cal_F'),
        moreText: [t('Cal_F_1')],
      },
    ],
    Provisions: [
      {
        id: 'a',
        name: t('Prov_A'),
      },
      {
        id: 'b',
        name: t('Prov_B'),
      },
      {
        id: 'c',
        name: t('Prov_C'),
      },
      {
        id: 'd',
        name: t('Prov_D'),
      },
    ],
    PartiesDPA: [
      {
        id: 'a',
        name: t('Parties_A'),
      },
      {
        id: 'b',
        name: t('Parties_B'),
      },
      {
        id: 'c',
        name: t('Parties_C'),
      },
      {
        id: 'd',
        name: t('Parties_D'),
      },
    ],
  });

  // useEffect(() => {
  //   setTableOfContent(getTranslatedList(t, 'tableOfContent'));
  //   setDefList(getTranslatedList(t, 'defList'));
  //   setDefItem((prevDefItem) => ({
  //     ...prevDefItem,
  //     responsibility: getTranslatedList(t, 'responsibility'),
  //     obligations: getTranslatedList(t, 'obligations'),
  //     Subject_Requests: getTranslatedList(t, 'subject_requests'),
  //     SubProcessor: getTranslatedList(t, 'subProcessor'),
  //     European_data: getTranslatedList(t, 'european_data'),
  //     California_data: getTranslatedList(t, 'california_data'),
  //     Provisions: getTranslatedList(t, 'provisions'),
  //     PartiesDPA: getTranslatedList(t, 'partiesDPA'),
  //   }));
  // }, [t]);

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleOnClick = (event) => {
    let element = event.target;
    while (element != null && !element.classList.contains('underlineSpan')) {
      element = element.parentElement;
    }

    if (element && element.dataset.url) {
      if (element.dataset.url.includes('https://')) {
        window.open(element.dataset.url, '_blank');
      } else {
        navigate(element.dataset.url, { state: 'send' });
        if (element.dataset.url === '/') {
          setTimeout(() => {
            const element = document.getElementById('ContactId');

            if (element) {
              const elementPosition =
                element.getBoundingClientRect().top + window.scrollY;
              const offsetPosition = elementPosition - 80;
              window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
              });
            }
          }, 500);
        }
        localStorage.setItem('scrollDPA', window.scrollY);
      }
    }
  };
  return (
    <div className="w-full">
      <p className="Text60 text-PrimaryT">{t('DataProcessingAg')}</p>
      <p className="mt-5 Text24Legal text-TernaryT max_sm:text-textNormal">
        {t('LastModifyDate')}
      </p>
      <div className="mt-[30px] flex flex-col gap-[16px]">
        <p className="Text16L">{t('DataProcessingAgT1')}</p>
        <p className="Text16L">{t('DataProcessingAgT2')}</p>
        <p className="Text16L">{t('DataProcessingAgT3')}</p>
      </div>
      {/* TABLE OF CONTENT */}
      <div className="mt-[30px] flex flex-col gap-3 w-full">
        <p className="Text24Legal">{t('TableOfContents')} : </p>
        <div className="flex flex-col gap-4 w-full">
          {tableOfContent.map((list1, index) => (
            <div
              className="flex gap-2 pl-4"
              key={index}
              onClick={() => handleClickScroll(list1.id)}
            >
              <p className="w-[6px] h-[6px] bg-PrimaryT rounded-full mt-[10px]"></p>
              <p className="Text16L w-[calc(100%-14px)] cursor-pointer font-medium text-PrimaryT underline text-textNormal max_sm:text-textSmall break-words">
                {list1.name}
              </p>
            </div>
          ))}
          <p
            className="Text16L cursor-pointer"
            onClick={() => handleClickScroll('Annex1')}
          >
            {t('Annex1')}
          </p>
          <p
            className="Text16L cursor-pointer"
            onClick={() => handleClickScroll('Annex2')}
          >
            {t('Annex2')}
          </p>
          <p className="Text16L">{t('Annex3')}</p>
        </div>
      </div>
      {/* 1. DEFINATION */}
      <Definition
        count={1}
        title={t('Definitions')}
        data={defList}
        defText={t('defText')}
        id="D_Definitions"
        handleOnClick={handleOnClick}
      />
      {/* 2 Customer Responsibilities */}
      <CommonAlphabeticComp
        title={t('CustomerResponsibilities')}
        count={2}
        data={defItem.responsibility}
        id="CustomerResponsibilities"
      />
      <CommonAlphabeticComp
        title={t('AvObligations')}
        count={3}
        data={defItem.obligations}
        id="AvObligations"
        handleOnClick={handleOnClick}
      />
      <CommonAlphabeticComp
        title={t('DataSubjectRequests')}
        count={4}
        data={defItem.Subject_Requests}
        id="DataSubjectRequests"
      />

      <SubProcessorComp
        title={t('SubProcessors')}
        count={5}
        data={defItem.SubProcessor}
        id="SubProcessors"
      />

      <CommonHeadText
        data={[t('DataTransfersT')]}
        title={t('DataTransfers')}
        count={6}
        id="DataTransfers"
      />

      <CommonHeadText
        data={[t('DemonstrationComplianceT')]}
        title={t('DemonstrationCompliance')}
        count={7}
        id="DemonstrationCompliance"
      />

      <ProvisionsEuropean
        title={t('AdditionalEuropean')}
        count={8}
        data={defItem.European_data}
        id="AdditionalEuropean"
      />
      <CommonAlphabeticComp
        title={t('AdditionalCalifornia')}
        count={9}
        data={defItem.California_data}
        isBullet
        id="AdditionalCalifornia"
      />
      <CommonAlphabeticComp
        title={t('Provisions')}
        count={10}
        data={defItem.Provisions}
        id="GeneralProvisions"
        handleOnClick={handleOnClick}
      />
      <CommonAlphabeticComp
        title={t('PartiesDPA')}
        count={11}
        data={defItem.PartiesDPA}
        id="PartiesDPA"
      />
      <Annex1 title={t('Annex1')} id="Annex1" handleOnClick={handleOnClick} />
      <Annex2 title={t('Annex2')} id="Annex2" />
    </div>
  );
};
// const getTranslatedList = (t, key) => {
//   const lists = {
//     tableOfContent: [
//       {
//         name: t('Definitions'),
//         id: 'D_Definitions',
//       },
//       {
//         name: t('CustomerResponsibilities'),
//         id: 'CustomerResponsibilities',
//       },
//       {
//         name: t('AvObligations'),
//         id: 'AvObligations',
//       },
//       {
//         name: t('DataSubjectRequests'),
//         id: 'DataSubjectRequests',
//       },
//       {
//         name: t('SubProcessors'),
//         id: 'SubProcessors',
//       },
//       {
//         name: t('DataTransfers'),
//         id: 'DataTransfers',
//       },
//       {
//         name: t('DemonstrationCompliance'),
//         id: 'DemonstrationCompliance',
//       },
//       {
//         name: t('AdditionalEuropean'),
//         id: 'AdditionalEuropean',
//       },
//       {
//         name: t('AdditionalCalifornia'),
//         id: 'AdditionalCalifornia',
//       },
//       {
//         name: t('GeneralProvisions'),
//         id: 'GeneralProvisions',
//       },
//       {
//         name: t('PartiesDPA'),
//         id: 'PartiesDPA',
//       },
//     ],
//     defList: [
//       t('Def1Pro'),
//       t('Def2Pro'),
//       t('Def3Pro'),
//       t('Def4Pro'),
//       t('Def5Pro'),
//       t('Def6Pro'),
//       t('Def7Pro'),
//       t('Def8Pro'),
//       t('Def9Pro'),
//       t('Def10Pro'),
//       t('Def11Pro'),
//       t('Def12Pro'),
//       t('Def13Pro'),
//       t('Def14Pro'),
//       t('Def15Pro'),
//       t('Def16Pro'),
//       t('Def17Pro'),
//       t('Def18Pro'),
//       t('Def19Pro'),
//       t('Def20Pro'),
//     ],
//     responsibility: [
//       {
//         id: 'a',
//         name: t('A_ComplianceLawsT'),
//         text: [
//           t('ComplianceLawsT1'),
//           t('ComplianceLawsT2'),
//           t('ComplianceLawsT3'),
//           t('ComplianceLawsT4'),
//         ],
//       },
//       {
//         id: 'b',
//         name: t('B_ControllerInstrT'),
//       },
//       {
//         id: 'c',
//         name: t('C_SecurityT'),
//       },
//     ],
//     obligations: [
//       {
//         id: 'a',
//         name: t('A_Obligations'),
//       },
//       {
//         id: 'b',
//         name: t('B_Obligations'),
//         text: [t('B_ObligationsT1'), t('B_ObligationsT2')],
//         moreText: [t('B_ObligationsT3')],
//       },
//       {
//         id: 'c',
//         name: t('C_Obligations'),
//       },
//       {
//         id: 'd',
//         name: t('D_Obligations'),
//       },
//       {
//         id: 'e',
//         name: t('E_Obligations'),
//       },
//       {
//         id: 'f',
//         name: t('F_Obligations'),
//         moreText: [t('F_T1'), t('F_T2')],
//       },
//     ],
//     subject_requests: [
//       {
//         moreText: [
//           t('SubjectRequest1'),
//           t('SubjectRequest2'),
//           t('SubjectRequest3'),
//         ],
//       },
//     ],
//     subProcessor: [
//       {
//         text: [t('ProcessorT1'), t('ProcessorT2'), t('ProcessorT3')],
//         list: [t('Processor1'), t('Processor2'), t('Processor3')],
//       },
//     ],
//     european_data: [
//       {
//         id: 'a',
//         name: t('A_Europian'),
//       },
//       {
//         id: 'b',
//         name: t('B_Europian'),
//       },
//       {
//         id: 'c',
//         name: t('C_Europian'),
//       },
//       {
//         id: 'd',
//         name: t('D_Europian'),
//       },
//       {
//         id: 'e',
//         name: t('E_Europian'),
//       },
//     ],
//     california_data: [
//       {
//         id: 'a',
//         name: t('Cal_A'),
//       },
//       {
//         id: 'b',
//         name: t('Cal_B'),
//       },
//       {
//         id: 'c',
//         name: t('Cal_C'),
//         text: [t('Cal_C_1'), t('Cal_C_2'), t('Cal_C_3')],
//       },
//       {
//         id: 'd',
//         name: t('Cal_D'),
//       },
//       {
//         id: 'e',
//         name: t('Cal_E'),
//       },
//       {
//         id: 'f',
//         name: t('Cal_F'),
//         moreText: [t('Cal_F_1')],
//       },
//     ],
//     provisions: [
//       {
//         id: 'a',
//         name: t('Prov_A'),
//       },
//       {
//         id: 'b',
//         name: t('Prov_B'),
//       },
//       {
//         id: 'c',
//         name: t('Prov_C'),
//       },
//       {
//         id: 'd',
//         name: t('Prov_D'),
//       },
//     ],
//     partiesDPA: [
//       {
//         id: 'a',
//         name: t('Parties_A'),
//       },
//       {
//         id: 'b',
//         name: t('Parties_B'),
//       },
//       {
//         id: 'c',
//         name: t('Parties_C'),
//       },
//       {
//         id: 'd',
//         name: t('Parties_D'),
//       },
//     ],
//   };

//   return lists[key];
// };

export default DPAContent;