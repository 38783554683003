import React from 'react';
import { ReactComponent as Grad1 } from '../assets/images/Login/SubtractL.svg';
import { ReactComponent as Grad2 } from '../assets/images/Login/SubtractR.svg';
import { ReactComponent as Grad3 } from '../assets/images/Login/SubtractL_Res.svg';
import { ReactComponent as Grad4 } from '../assets/images/Login/SubtractR_Res.svg';
import { useLocation } from 'react-router-dom';

const GradientBg = ({ children }) => {
  const location = useLocation();
  return (
    <div className="max-w-[1920px] m-auto relative h-screen overflow-hidden bg-white max-h-[1080px]">
      {location.pathname === '/sign-in' && (
        <>
          <Grad1 className="absolute z-10 top-0 left-0 max_1680:left-[-116px] max_1500:top-[-40px] max_1500:left-[-190px] max_1366:left-[-273px] max_1366:top-[-72px] max_xl1160:left-[-360px] max_md:left-[-450px] max_sm:hidden" />
          <Grad2 className="absolute z-10 bottom-[-50px] right-0 max_1680:right-[-116px] max_1680:bottom-[-70px] max_1500:right-[-190px] max_1366:right-[-245px] max_1366:bottom-[-100px] max_xl1160:right-[-344px] max_md:right-[-450px] max_sm:hidden" />
          <div className="hidden max_sm:block">
            <Grad3 className="absolute z-10 top-0 left-0" />
            <Grad4 className="absolute z-10 bottom-[-50px] right-0" />
          </div>
        </>
      )}
      <div className="absolute inset-0 z-20 h-screen overflow-auto max-h-[1080px]">
        {children}
      </div>
    </div>
  );
};

export default GradientBg;
