import React from 'react';
import CommonSection from '../Common/CommonSection';
import { CallIcon, GlobalIcon, LocationIcon } from '../../assets/icons/icon';
import ContactForm from './ContactForm';

const ContactUs = () => {
  return (
    <CommonSection>
      <div
        className="flex justify-between max_md:flex-col max_md:gap-[60px] max_sm:gap-[30px]"
        id="ContactId"
      >
        <div className="max-w-[500px] max_xl:max-w-[440px] max_md:max-w-full">
          <h2 className="Text60">Contact us</h2>
          <p className="Text20 mt-5">Let’s get in touch!</p>
          <div className="flex flex-col gap-[30px] mt-[60px] max_md:mt-10 max_sm:mt-[30px] max_sm:gap-5">
            <div className="flex gap-4 ">
              <LocationIcon />
              <p className="Text20">
                3423 Piedmont Rd Disco Suite Atlanta, GA 30305
              </p>
            </div>
            <div className="flex gap-4 ">
              <GlobalIcon />
              <p className="Text20">support@epifony.com</p>
            </div>
            <div className="flex gap-4 ">
              <CallIcon />
              <p className="Text20">+1 (404) 410-2730</p>
            </div>
          </div>
        </div>
        <div className="w-[1px] h-[690px] bg-StrokePrimary max_md:hidden"></div>
        <ContactForm />
      </div>
    </CommonSection>
  );
};

export default ContactUs;
