import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ModalCustom = ({ isOpen, onClose, title, children, manageClicked }) => {
  const location = useLocation();
  useEffect(() => {
    if (isOpen && location.pathname !== '/legal/cookie-policy') {
      // When modal is open, disable scrolling of body content
      document.body.style.overflow = 'hidden';
    } else if (location.pathname === '/legal/cookie-policy') {
      document.body.style.overflow = 'scroll !important';
    } else {
      // When modal is closed, enable scrolling of body content
      document.body.style.overflow = '';
    }
    // Cleanup function to restore default scrolling behavior when component unmounts
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen, location]);

  if (!isOpen) return null;

  return (
    <>
      {/* Background overlay */}
      <div
        className={`fixed top-0 right-0 left-0 z-40 w-full  ${
          location.pathname !== '/legal/cookie-policy'
            ? 'bg-black opacity-40 h-full'
            : location.pathname === '/legal/cookie-policy' &&
              manageClicked &&
              'bg-black opacity-40 h-full'
        }`}
      ></div>

      {/* Modal content */}
      <div
        className={`fixed  right-0 left-0 z-50 w-full  flex justify-center ${
          manageClicked ? 'top-0 items-center' : 'bottom-0 items-end'
        } ${
          location.pathname !== '/legal/cookie-policy'
            ? 'h-screen'
            : location.pathname === '/legal/cookie-policy' &&
              manageClicked &&
              'h-screen'
        }`}
      >
        <div
          className={`relative w-full ${
            manageClicked
              ? 'max-w-2xl max_800:max-w-xl max_600:max-w-[430px] max_sm:max-w-full max_sm:mx-5'
              : 'max-w-full'
          }`}
        >
          <div
            className={`relative p-[30px] max_sm:p-5 ${
              manageClicked ? 'rounded-[10px] bg-white' : 'bg-SecondaryT'
            }`}
            style={{ boxShadow: '0px 4px 130px 0px #3C425733' }}
          >
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalCustom;
