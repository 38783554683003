import React from 'react';
import NumberWithHeadingComp from './NumberWithHeadingComp';

const ConfidentialityComp = ({ title, data, count, id }) => {
  return (
    <div className="mt-[30px] flex flex-col" id={id}>
      <p className="Text24Legal">
        {count}. {title}
      </p>
      {data.map((uses, index3) => (
        <React.Fragment key={index3}>
          {index3 === 0 ? (
            <>
              {' '}
              <div className="mt-5 flex flex-col">
                <p className="Text20 text-SecondaryT font-semibold">
                  {uses.name}
                </p>
                <div className="mt-4 flex flex-col gap-4">
                  {uses.text.map((list, index4) => (
                    <div className="flex gap-3" key={index4}>
                      {index4 !== 0 && (
                        <p className="w-[6px] h-[6px] bg-TernaryT rounded-full mt-[10px]"></p>
                      )}
                      <p
                        className="Text16L w-[calc(100%-18px)]"
                        dangerouslySetInnerHTML={{
                          __html: list,
                        }}
                      ></p>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <>
              <NumberWithHeadingComp data={uses} />
            </>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default ConfidentialityComp;
