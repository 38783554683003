import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const WebAccessContent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleOnClick = (event) => {
    let element = event.target;
    while (element != null && !element.classList.contains('underlineSpan')) {
      element = element.parentElement;
    }

    if (element && element.dataset.url) {
      if (element.dataset.url.includes('https://')) {
        window.open(element.dataset.url, '_blank');
      } else {
        navigate(element.dataset.url, { state: 'send' });
        localStorage.setItem('scrollWA', window.scrollY);
      }
    }
  };
  return (
    <div className="">
      <p className="Text60 text-PrimaryT">{t('WebAccessibilityStatement')}</p>
      <p className="mt-5 Text24Legal text-TernaryT max_sm:text-textNormal">
        {t('LastModifyDate')}
      </p>
      <div className="mt-10 flex flex-col gap-4">
        <p
          className="Text16L"
          dangerouslySetInnerHTML={{ __html: t('WebAccessT1') }}
          onClick={handleOnClick}
        ></p>
        <p className="Text16L">{t('WebAccessT2')}</p>
      </div>
    </div>
  );
};

export default WebAccessContent;