import React from 'react';

const CommonHeadText = ({ count, title, data, id, handleOnClick }) => {
  return (
    <div className="mt-[30px] flex flex-col" id={id}>
      <p className="Text24Legal">
        {count}. {title}
      </p>
      {}
      <div className="mt-4 flex flex-col gap-4">
        {data.map((list, index) => (
          <p
            className="Text16L"
            key={index}
            dangerouslySetInnerHTML={{ __html: list }}
            onClick={handleOnClick}
          ></p>
        ))}
      </div>
    </div>
  );
};

export default CommonHeadText;
