import React from 'react';
import BulletPoints from '../BulletPoints';

const CommonForPrivacy = ({ title, count, data, pl, id, handleOnClick }) => {
  return (
    <div className="mt-[30px] flex flex-col" id={id}>
      <p className="Text24Legal">
        {count}. <span className="pl-1">{title}</span>
      </p>
      <div className="flex flex-col gap-4">
        {data.map((info, index1) => (
          <React.Fragment key={index1}>
            <div
              className={`flex mt-4 gap-2`}
              style={{ paddingLeft: pl ? pl : '' }}
            >
              <p className="w-3 Text18Normal text-SecondaryT text-[18px] font-semibold">
                {info.id}.
              </p>
              <p className="w-[calc(100%-20px)] Text18Normal text-SecondaryT text-[18px] font-semibold">
                {info.name}
              </p>
            </div>
            {info.text && (
              <>
                {info.text.map((textT, index1) => (
                  <p
                    className="Text16L"
                    key={index1}
                    dangerouslySetInnerHTML={{ __html: textT }}
                    onClick={handleOnClick}
                  ></p>
                ))}
              </>
            )}
            {info.subText && (
              <>
                {info.subText.map((subText, index2) => (
                  <React.Fragment key={index2}>
                    <p
                      className="Text16L font-semibold text-LightPrimary"
                      key={index2}
                      dangerouslySetInnerHTML={{ __html: subText.name }}
                    ></p>
                    {subText.text.map((innerSubText, index3) => (
                      <p
                        className="Text16L"
                        key={index3}
                        dangerouslySetInnerHTML={{ __html: innerSubText }}
                        onClick={handleOnClick}
                      ></p>
                    ))}
                    {subText.bulletPoints && (
                      <>
                        {subText.bulletPoints.map((points, index4) => (
                          <BulletPoints
                            data={points}
                            key={index4}
                            pl="20px"
                            handleOnClick={handleOnClick}
                          />
                        ))}
                      </>
                    )}
                  </React.Fragment>
                ))}
              </>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default CommonForPrivacy;
