import React from 'react';

import { Outlet } from 'react-router-dom';
import Layout from './Layout';

const CommonLayout = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default CommonLayout;
