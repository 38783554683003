import React, { useState } from 'react';
import CommonSection from '../Common/CommonSection';
import AutomationTool from '../../assets/images/Homepage/Image1.svg';
import SmartSearch from '../../assets/images/Homepage/Image2.svg';
import InstantUpdate from '../../assets/images/Homepage/Image3.svg';
import { ButtonFilled } from '../Button';
import { HomeEllipse1, HomeEllipse2 } from '../../assets/icons/icon';
import { useWindowSize } from '../../Hooks/useWindowSize';

const Automation = ({ handleClick }) => {
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 670;
  const [automationData] = useState([
    {
      image: AutomationTool,
      title: 'Automation Tools',
      text: 'Automate routine tasks to save time and focus on what really matters.',
    },
    {
      image: SmartSearch,
      title: 'Smart Search',
      text: 'Quickly find exactly what you need with our powerful, predictive search.',
    },
    {
      image: InstantUpdate,
      title: 'Instant Updates',
      text: 'Real-time updates keep your contact list fresh and accurate.',
    },
  ]);
  return (
    <div className="relative max-w-[1920px] m-auto max_sm:overflow-x-hidden">
      <div className="absolute top-[12%] right-[8%] max_md:right-[15%] max_xl:right-[12%] opacity-60 rotate-45 blur-[250px] -z-10">
        <HomeEllipse1
          style={{
            width: isMobileView ? '190' : '320',
            height: isMobileView ? '280' : '410',
          }}
        />
      </div>
      <div className="absolute top-[60%] left-[8%] opacity-60 rotate-[-30deg] blur-[250px] -z-10">
        <HomeEllipse2
          style={{
            width: isMobileView ? '190' : '320',
            height: isMobileView ? '280' : '410',
          }}
        />
      </div>
      <CommonSection>
        <div>
          <h2 className="Text60 text-center max-w-[940px] m-auto">
            Save time with automations
          </h2>
          <p className="Text20 text-center mt-10">
            We believe managing contacts should be simple, not a chore.
          </p>
          <div className="flex flex-col gap-[120px] max_md:gap-[60px] mt-[60px]">
            {automationData.map((data, index) => (
              <div
                className="flex justify-between items-center px-[90px] max_xl:px-10 max_md:px-0 max_md:flex-col max_md:gap-10 max_sm:gap-5"
                key={index}
              >
                <div>
                  <img
                    src={data.image}
                    alt={data.title}
                    style={{
                      boxShadow:
                        index === 0
                          ? '8px 8px 70px 0px rgba(223, 228, 236, 0.30)'
                            : index === 1
                            ? ''
                          : '8px 8px 70px 0px rgba(223, 228, 236, 0.30)',
                    }}
                    className="rounded-2xl"
                  />
                </div>
                <div className="max-w-[450px] max_xl:max-w-[380px] max_md:max-w-[450px]">
                  <p className="Text40">{data.title}</p>
                  <p className="mt-3 Text20">{data.text}</p>
                  <div className="mt-6 w-max">
                    <ButtonFilled
                      title="Learn more"
                      onClick={() => handleClick('ContactId')}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </CommonSection>
    </div>
  );
};

export default Automation;
