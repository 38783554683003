import React from 'react';

const NumberWithSubHeadingComp = ({ data, subData, handleOnClick }) => {
  return (
    <div className="mt-5 flex flex-col">
      <p className="Text20 text-SecondaryT font-bold">{data.name}</p>
      {subData.map((subHeading, index5) => (
        <React.Fragment key={index5}>
          <div className="mt-4">
            <p className="Text16L font-semibold">{subHeading.name}</p>
            <div className="mt-4 flex flex-col gap-4">
              {subHeading.text.map((list, index4) => (
                <p
                  className="Text16L"
                  key={index4}
                  dangerouslySetInnerHTML={{ __html: list }}
                  onClick={handleOnClick}
                ></p>
              ))}
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};
export default NumberWithSubHeadingComp;
