import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonFilled } from '../Button';

const CookiesConsent = ({ handleReject, handleManage, handleSelection }) => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-between items-center gap-5 max_md:flex-col max_md:items-start">
      <div className="max-w-[70%] max_md:max-w-full">
        <p
          className="Text16Normal mt-4 text-white
         "
        >
          We use essential cookies for site functionality and additional cookies
          to enhance features, personalize content, and analyze usage. You can
          configure and accept the use of cookies, and modify your consent
          options, at any time. For more information, you can read about our{' '}
          <span
            className="cursor-pointer Text16Normal text-ButtonPrimary underline"
            onClick={() => {
              navigate('/legal/cookie-policy')
              setTimeout(() => {
                window.scrollTo(0,0)
              },300)
            }}
          >
            Cookie Policy
          </span>
        </p>
      </div>

      <div className="flex gap-3 items-center max_md:justify-between max_md:w-full max_sm:flex-col-reverse max_sm:gap-5">
        <p
          className="cursor-pointer underline Text16Normal font-medium text-ButtonPrimary w-max h-max"
          onClick={handleManage}
        >
          Manage Cookies
        </p>
        <div className="flex gap-3 items-center max_sm:gap-[6px]">
          <ButtonFilled
            width="98px"
            background="#454545"
            fontSize="16px"
            title="Reject"
            onClick={() => handleSelection('reject')}
            border
            fontColor="#FDBA12"
          />
          <ButtonFilled
            title="Accept all"
            isFilled={true}
            width="118px"
            fontWeight="500"
            onClick={() => handleSelection('accept')}
          />
        </div>
      </div>
    </div>
  );
};

export default CookiesConsent;
