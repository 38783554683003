import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CommonAlphabeticComp from '../CommonAlphabeticComp';
import TitleWithText from '../TitleWithText';
import BulletPoints from '../BulletPoints';
import ProperUseOfDCService from '../AcceptableUse/ProperUseOfDCService';

const AcceptableUseContent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [tableOfContent, setTableOfContent] = useState([
    {
      name: t('ReportingViolations'),
      id: 'ReportingViolations',
    },
    {
      name: t('ProhibitedActions'),
      id: 'ProhibitedActions',
    },
    {
      name: t('EmailRequirements'),
      id: 'EmailRequirements',
    },
    {
      name: t('TelephoneTextingMessaging'),
      id: 'TelephoneTextingMessaging',
    },
    {
      name: t('NoDisruption'),
      id: 'NoDisruption',
    },
    {
      name: t('UsageAVService'),
      id: 'UsageAVService',
    },
    {
      name: t('RestrictedIndustries'),
      id: 'RestrictedIndustries',
    },
    {
      name: t('AVTrademarkUse'),
      id: 'AVTrademarkUse',
    },
    {
      name: t('DeveloperTermsN'),
      id: 'DeveloperTerms',
    },
    {
      name: t('GeneralTerm'),
      id: 'GeneralTerm',
    },
  ]);
  const [acceptableList, setAcceptableList] = useState({
    ProhibitedActions: [
      {
        id: 'a',
        name: t('Prohibited_A'),
      },
      {
        id: 'b',
        name: t('Prohibited_B'),
      },
      {
        id: 'c',
        name: t('Prohibited_C'),
      },
    ],
    EmailRequirements: [
      {
        id: 'a',
        name: t('Email_A'),
      },
      {
        id: 'b',
        name: t('Email_B'),
      },
      {
        id: 'c',
        name: t('Email_C'),
      },
    ],
    TelephoneTM: [
      {
        id: 'i',
        text: t('TelephoneText1'),
      },
      {
        id: 'ii',
        text: t('TelephoneText2'),
      },
      {
        id: 'iii',
        text: t('TelephoneText3'),
      },
      {
        id: 'iv',
        text: t('TelephoneText4'),
      },
      {
        id: 'v',
        text: t('TelephoneText5'),
      },
      {
        id: 'vi',
        text: t('TelephoneText6'),
      },
      {
        id: 'vii',
        text: t('TelephoneText7'),
      },
      {
        id: 'viii',
        text: t('TelephoneText8'),
      },
    ],
    NoDisruption: [
      {
        id: 'a',
        name: t('No_des_A'),
      },
      {
        id: 'b',
        name: t('No_des_B'),
      },
      {
        id: 'c',
        name: t('No_des_C'),
      },
      {
        id: 'd',
        name: t('No_des_D'),
      },
      {
        id: 'e',
        name: t('No_des_E'),
      },
      {
        id: 'f',
        name: t('No_des_F'),
      },
      {
        id: 'g',
        name: t('No_des_G'),
      },
      {
        id: 'h',
        name: t('No_des_H'),
      },
      {
        id: 'i',
        name: t('No_des_I'),
      },
    ],
    RestrictedIndustries: [
      t('Cryptocurrency'),
      t('NonFungibleTokens'),
      t('EscortDatingServices'),
      t('PharmaceuticalProducts'),
      t('WorkFromHome'),
      t('GamblingServicesProducts'),
      t('MultiLevelMarketing'),
      t('ListBrokers'),
      t('SellingLikes'),
    ],
    GeneralTerm: [
      {
        id: 'a',
        name: t('GeneralTerm_A'),
      },
      {
        id: 'b',
        name: t('GeneralTerm_B'),
      },
      {
        id: 'c',
        name: t('GeneralTerm_C'),
      },
    ],
  });
  // useEffect(() => {
  //   setTableOfContent(getTranslatedList(t, 'tableOfContent'));
  //   setAcceptableList((prevItems) => ({
  //     ...prevItems,
  //     ProhibitedActions: getTranslatedList(t, 'prohibited_actions'),
  //     EmailRequirements: getTranslatedList(t, 'email_requirements'),
  //     TelephoneTM: getTranslatedList(t, 'telephoneTM'),
  //     NoDisruption: getTranslatedList(t, 'no_disruption'),
  //     RestrictedIndustries: getTranslatedList(t, 'restricted_industries'),
  //     GeneralTerm: getTranslatedList(t, 'general_term'),
  //   }));
  // }, [t]);
  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleOnClick = (event) => {
    let element = event.target;
    while (element != null && !element.classList.contains('underlineSpan')) {
      element = element.parentElement;
    }

    if (element && element.dataset.url) {
      if (element.dataset.url.includes('https://')) {
        window.open(element.dataset.url, '_blank');
      } else {
        if (element.dataset.url === '/legal/acceptable-use/') {
          window.scrollTo(0, 0);
        } else {
          navigate(element.dataset.url, { state: 'send' });
          localStorage.setItem('scrollA', window.scrollY);
        }
      }
    }
  };

  return (
    <div className="">
      <p className="Text60 text-PrimaryT">{t('AcceptablePolicy')}</p>
      <p className="mt-5 Text24Legal text-TernaryT max_sm:text-textNormal">
        {t('LastModifyDate')}
      </p>
      <div className="mt-[30px] flex flex-col gap-[16px]">
        <p className="Text16L">{t('AcceptableUseT1')}</p>
      </div>
      <div className="mt-[30px] flex flex-col gap-3">
        <div className="flex flex-col gap-4">
          {tableOfContent.map((list1, index) => (
            <div className="flex gap-2" key={index}>
              <p className="w-4 Text16L text-PrimaryT">{index + 1}.</p>
              <p
                className="Text16L w-[calc(100%-14px)] cursor-pointer font-medium text-PrimaryT underline text-textNormal max_sm:text-textSmall break-words"
                onClick={() => handleClickScroll(list1.id)}
              >
                {list1.name}
              </p>
            </div>
          ))}
          <p className="Text16L">{t('AcceptableUseT2')}</p>
          <p className="Text16L">{t('AcceptableUseT3')}</p>
        </div>
      </div>
      {/* 1 ReportingViolations */}
      <div className="mt-[30px] flex flex-col" id="ReportingViolations">
        <p className="text22">
          1. <span className="pl-1">{t('ReportingViolations')}</span>
        </p>
        <div className="flex gap-1 mt-4 pl-6">
          <p className="w-5 Text16L text-LightSecondary font-medium">a.</p>
          <div className="w-[calc(100%-24px)] Text16L">{t('Response_1')}</div>
        </div>
      </div>
      <CommonAlphabeticComp
        title={t('ProhibitedActions')}
        count={2}
        data={acceptableList.ProhibitedActions}
        pl="24px"
        id="ProhibitedActions"
      />
      <CommonAlphabeticComp
        title={t('EmailRequirements')}
        count={3}
        data={acceptableList.EmailRequirements}
        pl="24px"
        id="EmailRequirements"
      />
      {/* //////////  4 TelephoneTextingMessaging   //////*/}
      <TitleWithText
        title={t('TelephoneTextingMessaging')}
        count={4}
        text={[t('TelephoneText')]}
        id="TelephoneTextingMessaging"
      >
        <div className="mt-4 gap-4 flex flex-col">
          {acceptableList.TelephoneTM.map((pointsList, index2) => (
            <BulletPoints
              data={pointsList.text}
              key={index2}
              isRoman
              id={pointsList.id}
              pl="24px"
            />
          ))}
        </div>
      </TitleWithText>
      {/* \\\\\\\   5 NoDisruption   \\\\\\\\\\*/}
      <TitleWithText
        title={t('NoDisruption')}
        count={5}
        text={[t('YouAgreeNotTo')]}
        id="NoDisruption"
      >
        <div className="mt-4 gap-4 flex flex-col">
          {acceptableList.NoDisruption.map((pointsList, index3) => (
            <BulletPoints
              data={pointsList.name}
              key={index3}
              isRoman
              id={pointsList.id}
              pl="24px"
            />
          ))}
          <p className="Text16L">{t('No_des_T1')}</p>
          <p className="Text16L">{t('No_des_T2')}</p>
        </div>
      </TitleWithText>
      {/* \\\\\\\ 6 UsageAVService  \\\\\\\\\\*/}
      <ProperUseOfDCService id="UsageAVService" handleOnClick={handleOnClick} />
      {/* \\\\\\\ 7 RestrictedIndustries  \\\\\\\\\\*/}
      <TitleWithText
        title={t('RestrictedIndustries')}
        count={7}
        text={[t('RestrictedIndT')]}
        id="RestrictedIndustries"
      >
        <div className="mt-4 flex flex-col gap-4">
          {acceptableList.RestrictedIndustries.map((points, index4) => (
            <BulletPoints data={points} key={index4} />
          ))}
        </div>
      </TitleWithText>
      {/* \\\\\\\ 8 AvTrademarkUse   \\\\\\\\\\*/}
      <TitleWithText
        title={t('AVTrademarkUse')}
        count={8}
        text={[t('TradeMarkT')]}
        id="AVTrademarkUse"
      ></TitleWithText>
      {/* \\\\\\\ 9 DeveloperTerms  \\\\\\\\\\*/}
      <TitleWithText title={t('DeveloperTerms')} count={9} id="DeveloperTerms">
        <p
          className="Text16L mt-6"
          dangerouslySetInnerHTML={{ __html: t('DevTermT') }}
          onClick={handleOnClick}
        ></p>
      </TitleWithText>
      {/* 10 GeneralTerm */}
      <TitleWithText
        title={t('GeneralTerm')}
        count={10}
        text={[t('GeneralTermT')]}
        id="GeneralTerm"
      >
        <div className="mt-4 gap-4 flex flex-col">
          {acceptableList.GeneralTerm.map((pointsList, index2) => (
            <BulletPoints
              data={pointsList.name}
              key={index2}
              isRoman
              bgColor="#353535"
              id={pointsList.id}
              pl="24px"
              handleOnClick={handleOnClick}
            />
          ))}
          <p className="Text16L">{t('GeneralTermT1')}</p>
        </div>
      </TitleWithText>
    </div>
  );
};
// const getTranslatedList = (t, key) => {
//   const lists = {
//     tableOfContent: [
//       {
//         name: t('ReportingViolations'),
//         id: 'ReportingViolations',
//       },
//       {
//         name: t('ProhibitedActions'),
//         id: 'ProhibitedActions',
//       },
//       {
//         name: t('EmailRequirements'),
//         id: 'EmailRequirements',
//       },
//       {
//         name: t('TelephoneTextingMessaging'),
//         id: 'TelephoneTextingMessaging',
//       },
//       {
//         name: t('NoDisruption'),
//         id: 'NoDisruption',
//       },
//       {
//         name: t('UsageAVService'),
//         id: 'UsageAVService',
//       },
//       {
//         name: t('RestrictedIndustries'),
//         id: 'RestrictedIndustries',
//       },
//       {
//         name: t('AVTrademarkUse'),
//         id: 'AVTrademarkUse',
//       },
//       {
//         name: t('DeveloperTermsN'),
//         id: 'DeveloperTerms',
//       },
//       {
//         name: t('GeneralTerm'),
//         id: 'GeneralTerm',
//       },
//     ],
//     prohibited_actions: [
//       {
//         id: 'a',
//         name: t('Prohibited_A'),
//       },
//       {
//         id: 'b',
//         name: t('Prohibited_B'),
//       },
//       {
//         id: 'c',
//         name: t('Prohibited_C'),
//       },
//     ],
//     email_requirements: [
//       {
//         id: 'a',
//         name: t('Email_A'),
//       },
//       {
//         id: 'b',
//         name: t('Email_B'),
//       },
//       {
//         id: 'c',
//         name: t('Email_C'),
//       },
//     ],
//     telephoneTM: [
//       {
//         id: 'i',
//         text: t('TelephoneText1'),
//       },
//       {
//         id: 'ii',
//         text: t('TelephoneText2'),
//       },
//       {
//         id: 'iii',
//         text: t('TelephoneText3'),
//       },
//       {
//         id: 'iv',
//         text: t('TelephoneText4'),
//       },
//       {
//         id: 'v',
//         text: t('TelephoneText5'),
//       },
//       {
//         id: 'vi',
//         text: t('TelephoneText6'),
//       },
//       {
//         id: 'vii',
//         text: t('TelephoneText7'),
//       },
//       {
//         id: 'viii',
//         text: t('TelephoneText8'),
//       },
//     ],
//     no_disruption: [
//       {
//         id: 'a',
//         name: t('No_des_A'),
//       },
//       {
//         id: 'b',
//         name: t('No_des_B'),
//       },
//       {
//         id: 'c',
//         name: t('No_des_C'),
//       },
//       {
//         id: 'd',
//         name: t('No_des_D'),
//       },
//       {
//         id: 'e',
//         name: t('No_des_E'),
//       },
//       {
//         id: 'f',
//         name: t('No_des_F'),
//       },
//       {
//         id: 'g',
//         name: t('No_des_G'),
//       },
//       {
//         id: 'h',
//         name: t('No_des_H'),
//       },
//       {
//         id: 'i',
//         name: t('No_des_I'),
//       },
//     ],
//     restricted_industries: [
//       t('Cryptocurrency'),
//       t('NonFungibleTokens'),
//       t('EscortDatingServices'),
//       t('PharmaceuticalProducts'),
//       t('WorkFromHome'),
//       t('GamblingServicesProducts'),
//       t('MultiLevelMarketing'),
//       t('ListBrokers'),
//       t('SellingLikes'),
//     ],
//     general_term: [
//       {
//         id: 'a',
//         name: t('GeneralTerm_A'),
//       },
//       {
//         id: 'b',
//         name: t('GeneralTerm_B'),
//       },
//       {
//         id: 'c',
//         name: t('GeneralTerm_C'),
//       },
//     ],
//   };

//   return lists[key];
// };


export default AcceptableUseContent;