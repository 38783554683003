import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import TitleWithText from '../TitleWithText';
import NumberWithHeadingComp from '../NumberWithHeadingComp';
import BulletPoints from '../BulletPoints';


const ProductSpecificContent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [specificProductData, setSpecificProductData] = useState({
    CommunicationPlatform: [
      {
        id: '1.1',
        text: [t('Com1')],
        name: t('Com1_T'),
      },
      {
        id: '1.2',
        text: [t('Com2')],
        name: t('Com2_T'),
      },
      {
        id: '1.3',
        text: [t('Com3')],
        name: t('Com3_T'),
      },
      {
        id: '1.4',
        text: [t('Com4')],
        name: t('Com4_T'),
      },
      {
        id: '1.5',
        text: [t('Com5')],
        name: t('Com5_T'),
      },
    ],
    PaymentPlatform: [
      {
        id: '2.1',
        text: [t('PayPlatform1')],
        name: t('PayPlatform1_T'),
      },
      {
        id: '2.2',
        text: [t('PayPlatform2')],
        name: t('PayPlatform2_T'),
      },
    ],
    CRM: [
      {
        id: '3.1',
        name: t('CRM1_T'),
        text: [t('CRM1_1'), t('CRM1_2'), t('CRM1_3')],
      },
      {
        id: '3.2',
        text: [t('CRM2_1')],
        name: t('CRM2_T'),
      },
      {
        id: '3.3',
        name: t('CRM3_T'),
        text: [t('CRM3_1'), t('CRM3_2'), t('CRM3_3')],
      },
      {
        id: '3.4',
        name: t('CRM4_T'),
        text: [t('CRM4_1'), t('CRM4_2')],
      },
    ],
    AITools: [
      {
        id: '4.1',
        text: [t('AI_1')],
        name: t('AI_1_T'),
      },
      {
        id: '4.2',
        text: [t('AI_2')],
        name: t('AI_2_T'),
      },
    ],
    OtherTerms: [
      {
        id: '5.1',
        name: t('Terms1_T'),
        text: [t('Terms1_1'), t('Terms1_2')],
      },
      {
        id: '5.2',
        name: t('Terms2_T'),
        text: [t('Terms2_1')],
      },
      {
        id: '5.3',
        name: t('Terms3_T'),
        text: [t('Terms3_1'), t('Terms3_2')],
      },
      {
        id: '5.4',
        name: t('Terms4_T'),
        text: [t('Terms4_1')],
      },
      {
        id: '5.5',
        name: t('Terms5_T'),
        text: [t('Terms5_1')],
      },
      {
        id: '5.6',
        name: t('Terms6_T'),
        text: [t('Terms6_1')],
      },
      {
        id: '5.7',
        name: t('Terms7_T'),
        text: [t('Terms7_1'), t('Terms7_2'), t('Terms7_3')],
      },
      {
        id: '5.8',
        name: t('Terms8_T'),
        text: [t('Terms8_1')],
      },
      {
        id: '5.9',
        name: t('Terms9_T'),
        text: [t('Terms9_1')],
      },
      {
        id: '5.10',
        name: t('Terms10_T'),
        text: [t('Terms10_1')],
      },
      {
        id: '5.11',
        name: t('Terms11_T'),
        text: [t('Terms11_1')],
      },
      {
        id: '5.12',
        name: t('Terms12_T'),
        text: [t('Terms12_1'), t('Terms12_2')],
        points: [
          t('Terms12_3'),
          t('Terms12_4'),
          t('Terms12_5'),
          t('Terms12_6'),
        ],
        pointsLast: [t('Terms12_7'), t('Terms12_8'), t('Terms12_9')],
      },
    ],
    OtherService: [
      {
        name: t('CommunicationServices'),
        text: [t('CommunicationServices1'), t('CommunicationServices2')],
      },
      {
        name: t('EpifonyDirectory'),
        text: [t('EpifonyDirectoryT')],
      },
      {
        name: t('EpifonyAcademy'),
        text: [t('EpifonyAcademyT')],
      },
      {
        name: t('InsightsEnrichment'),
        text: [t('InsightsEnrichmentT1'), t('InsightsEnrichmentT2')],
      },
      {
        name: t('Calling'),
        text: [t('CallingT')],
      },
      {
        name: t('CallRecording6'),
        text: [t('CallRecording6T')],
      },
      {
        name: t('Texting'),
        text: [t('TextingT1'), t('TextingT2')],
      },
      {
        name: t('WhatsAppIntegration'),
        text: [t('WhatsAppIntegrationT1'), t('WhatsAppIntegrationT2')],
      },
      {
        name: t('BetaServices'),
        text: [t('BetaServicesT')],
      },
      {
        name: t('SitesProducts'),
        text: [t('SitesProductsT')],
      },
    ],
    AIProducts: [
      {
        name: t('InputOutput'),
        text: [t('InputOutputT')],
      },
      {
        name: t('OwnershipRights'),
        text: [t('OwnershipRightsT')],
      },
      {
        name: t('ServiceProviders'),
        text: [t('ServiceProvidersT')],
      },
      {
        name: t('AvailabilityModi'),
        text: [t('AvailabilityModiT')],
      },
    ],
    ServiceCommitment: [
      {
        name: t('8Definitions'),
        text: [t('8DefinitionsT1'), t('8DefinitionsT2'), t('8DefinitionsT3')],
      },
      {
        name: t('Commitment'),
        text: [t('CommitmentT1'), t('CommitmentT2')],
      },
    ],
    CustomerSupport: [
      {
        name: t('PhoneSupport'),
        text: [t('PhoneSupportT')],
      },
      {
        name: t('EmailSupport'),
        text: [t('EmailSupportT')],
      },
      {
        name: t('SupportLimitations'),
        text: [t('SupportLimitationsT')],
      },
      {
        name: t('FreeServices'),
        text: [t('FreeServicesT1'), t('FreeServicesT2')],
      },
    ],
  });

  // useEffect(() => {
  //   setSpecificProductData((prevData) => ({
  //     ...prevData,
  //     CommunicationPlatform: getTranslatedList(t, 'communication'),
  //     PaymentPlatform: getTranslatedList(t, 'Payment_Platform'),
  //     CRM: getTranslatedList(t, 'CRM'),
  //     AITools: getTranslatedList(t, 'AI_Tools'),
  //     OtherTerms: getTranslatedList(t, 'Other_terms'),
  //     OtherService: getTranslatedList(t, 'Other_service'),
  //     AIProducts: getTranslatedList(t, 'AI_Products'),
  //     ServiceCommitment: getTranslatedList(t, 'Service_Commitment'),
  //     CustomerSupport: getTranslatedList(t, 'Customer_Support'),
  //   }));
  // }, [t]);
  const handleOnClick = (event) => {
    let element = event.target;
    while (element != null && !element.classList.contains('underlineSpan')) {
      element = element.parentElement;
    }

    if (element && element.dataset.url) {
      if (element.dataset.url.includes('http')) {
        window.open(element.dataset.url, '_blank');
      } else {
        navigate(element.dataset.url, { state: 'send' });
        localStorage.setItem('scrollS', window.scrollY);
      }
    }
  };
  return (
    <div className="">
      <p className="Text60 text-PrimaryT">{t('ProductTerms')}</p>
      <p className="mt-5 Text24Legal text-TernaryT max_sm:text-textNormal">
        {t('LastModifyDate')}
      </p>
      <p className="text-SecondaryT text-[18px] font-semibold mt-10">
        {t('PLEASEREADTERMSCAREFULLY')}
      </p>
      <div className="mt-[30px] flex flex-col gap-4">
        <p
          className="Text16L"
          dangerouslySetInnerHTML={{ __html: t('Spec1') }}
          onClick={handleOnClick}
        ></p>
        <p
          className="Text16L"
          dangerouslySetInnerHTML={{ __html: t('Spec2') }}
          onClick={handleOnClick}
        ></p>
      </div>
      {/* 1.  CommunicationPlatform */}
      <TitleWithText
        title={t('CommunicationPlatform')}
        count={1}
        id="CommunicationPlatform"
      >
        {specificProductData.CommunicationPlatform.map((pointsList, index5) => (
          <NumberWithHeadingComp data={pointsList} key={index5} />
        ))}
      </TitleWithText>
      {/* 2.. PaymentPlatform */}
      <TitleWithText
        title={t('PaymentPlatform')}
        count={2}
        id="PaymentPlatform"
      >
        {specificProductData.PaymentPlatform.map((pointsList, index2) => (
          <NumberWithHeadingComp
            data={pointsList}
            key={index2}
            handleOnClick={handleOnClick}
          />
        ))}
      </TitleWithText>
      {/* 3 ..CRM */}
      <TitleWithText title={t('CRM')} count={3} id="PaymentPlatform">
        {specificProductData.CRM.map((pointsList, index2) => (
          <NumberWithHeadingComp
            data={pointsList}
            key={index2}
            handleOnClick={handleOnClick}
          />
        ))}
      </TitleWithText>
      {/* 4...AI Tools */}
      <TitleWithText title={t('AITools')} count={4} id="CRM">
        {specificProductData.AITools.map((pointsList, index3) => (
          <NumberWithHeadingComp
            data={pointsList}
            key={index3}
            handleOnClick={handleOnClick}
          />
        ))}
      </TitleWithText>
      {/* 5...Other terms */}
      <TitleWithText title={t('OtherTerms')} count={5} id="OtherTerms">
        {specificProductData.OtherTerms.map((pointsList, index5) => (
          <React.Fragment key={index5}>
            <NumberWithHeadingComp
              data={pointsList}
              handleOnClick={handleOnClick}
            />
            {pointsList.points && (
              <>
                <div className="flex flex-col gap-4 mt-4">
                  {pointsList.points.map((point, index4) => (
                    <BulletPoints
                      data={point}
                      key={index4}
                      bgColor="#747475"
                      handleOnClick={handleOnClick}
                      // pl="32px"
                    />
                  ))}
                </div>
                <div className="flex flex-col gap-4 mt-4">
                  {pointsList?.pointsLast?.map((texts, index1) => (
                    <p
                      key={index1}
                      className={`smallText16Normal`}
                      dangerouslySetInnerHTML={{
                        __html: texts,
                      }}
                      onClick={handleOnClick}
                    ></p>
                  ))}
                </div>
              </>
            )}
          </React.Fragment>
        ))}
      </TitleWithText>
      {/* 6.. OTHER SERVICES */}
      <TitleWithText title={t('OTHERSERVICES')} count={6} id="OtherService">
        {specificProductData.OtherService.map((pointsList, index5) => (
          <NumberWithHeadingComp data={pointsList} key={index5} />
        ))}
      </TitleWithText>
      {/* 7.. AI PRODUCTS */}
      <TitleWithText
        title={t('AIPRODUCTS')}
        count={7}
        id="AI_Products"
        text={[t('AIProductsT')]}
      >
        {specificProductData.AIProducts.map((pointsList, index6) => (
          <NumberWithHeadingComp data={pointsList} key={index6} />
        ))}
      </TitleWithText>
      {/* 8.. SERVICE UPTIME COMMITMENT */}
      <TitleWithText
        title={t('SERVICE_COMMITMENT')}
        count={8}
        id="SERVICE_COMMITMENT"
      >
        {specificProductData.ServiceCommitment.map((pointsList, index6) => (
          <NumberWithHeadingComp data={pointsList} key={index6} />
        ))}
      </TitleWithText>
      {/* 9.. CUSTOMER SUPPORT*/}
      <TitleWithText
        title={t('CUSTOMER_SUPPORT')}
        count={9}
        id="CUSTOMER_SUPPORT"
      >
        {specificProductData.CustomerSupport.map((pointsList, index6) => (
          <NumberWithHeadingComp data={pointsList} key={index6} />
        ))}
      </TitleWithText>
    </div>
  );
};

// const getTranslatedList = (t, key) => {
//   const lists = {
//     communication: [
//       {
//         id: '1.1',
//         text: [t('Com1')],
//         name: t('Com1_T'),
//       },
//       {
//         id: '1.2',
//         text: [t('Com2')],
//         name: t('Com2_T'),
//       },
//       {
//         id: '1.3',
//         text: [t('Com3')],
//         name: t('Com3_T'),
//       },
//       {
//         id: '1.4',
//         text: [t('Com4')],
//         name: t('Com4_T'),
//       },
//       {
//         id: '1.5',
//         text: [t('Com5')],
//         name: t('Com5_T'),
//       },
//     ],
//     Payment_Platform: [
//       {
//         id: '2.1',
//         text: [t('PayPlatform1')],
//         name: t('PayPlatform1_T'),
//       },
//       {
//         id: '2.2',
//         text: [t('PayPlatform2')],
//         name: t('PayPlatform2_T'),
//       },
//     ],
//     CRM: [
//       {
//         id: '3.1',
//         name: t('CRM1_T'),
//         text: [t('CRM1_1'), t('CRM1_2'), t('CRM1_3')],
//       },
//       {
//         id: '3.2',
//         text: [t('CRM2_1')],
//         name: t('CRM2_T'),
//       },
//       {
//         id: '3.3',
//         name: t('CRM3_T'),
//         text: [t('CRM3_1'), t('CRM3_2'), t('CRM3_3')],
//       },
//       {
//         id: '3.4',
//         name: t('CRM4_T'),
//         text: [t('CRM4_1'), t('CRM4_2')],
//       },
//     ],
//     AI_Tools: [
//       {
//         id: '4.1',
//         text: [t('AI_1')],
//         name: t('AI_1_T'),
//       },
//       {
//         id: '4.2',
//         text: [t('AI_2')],
//         name: t('AI_2_T'),
//       },
//     ],
//     Other_terms: [
//       {
//         id: '5.1',
//         name: t('Terms1_T'),
//         text: [t('Terms1_1'), t('Terms1_2')],
//       },
//       {
//         id: '5.2',
//         name: t('Terms2_T'),
//         text: [t('Terms2_1')],
//       },
//       {
//         id: '5.3',
//         name: t('Terms3_T'),
//         text: [t('Terms3_1'), t('Terms3_2')],
//       },
//       {
//         id: '5.4',
//         name: t('Terms4_T'),
//         text: [t('Terms4_1')],
//       },
//       {
//         id: '5.5',
//         name: t('Terms5_T'),
//         text: [t('Terms5_1')],
//       },
//       {
//         id: '5.6',
//         name: t('Terms6_T'),
//         text: [t('Terms6_1')],
//       },
//       {
//         id: '5.7',
//         name: t('Terms7_T'),
//         text: [t('Terms7_1'), t('Terms7_2'), t('Terms7_3')],
//       },
//       {
//         id: '5.8',
//         name: t('Terms8_T'),
//         text: [t('Terms8_1')],
//       },
//       {
//         id: '5.9',
//         name: t('Terms9_T'),
//         text: [t('Terms9_1')],
//       },
//       {
//         id: '5.10',
//         name: t('Terms10_T'),
//         text: [t('Terms10_1')],
//       },
//       {
//         id: '5.11',
//         name: t('Terms11_T'),
//         text: [t('Terms11_1')],
//       },
//       {
//         id: '5.12',
//         name: t('Terms12_T'),
//         text: [t('Terms12_1'), t('Terms12_2')],
//         points: [
//           t('Terms12_3'),
//           t('Terms12_4'),
//           t('Terms12_5'),
//           t('Terms12_6'),
//         ],
//         pointsLast: [t('Terms12_7'), t('Terms12_8'), t('Terms12_9')],
//       },
//     ],
//     Other_service: [
//       {
//         name: t('CommunicationServices'),
//         text: [t('CommunicationServices1'), t('CommunicationServices2')],
//       },
//       {
//         name: t('EpifonyDirectory'),
//         text: [t('EpifonyDirectoryT')],
//       },
//       {
//         name: t('EpifonyAcademy'),
//         text: [t('EpifonyAcademyT')],
//       },
//       {
//         name: t('InsightsEnrichment'),
//         text: [t('InsightsEnrichmentT1'), t('InsightsEnrichmentT2')],
//       },
//       {
//         name: t('Calling'),
//         text: [t('CallingT')],
//       },
//       {
//         name: t('CallRecording6'),
//         text: [t('CallRecording6T')],
//       },
//       {
//         name: t('Texting'),
//         text: [t('TextingT1'), t('TextingT2')],
//       },
//       {
//         name: t('WhatsAppIntegration'),
//         text: [t('WhatsAppIntegrationT1'), t('WhatsAppIntegrationT2')],
//       },
//       {
//         name: t('BetaServices'),
//         text: [t('BetaServicesT')],
//       },
//       {
//         name: t('SitesProducts'),
//         text: [t('SitesProductsT')],
//       },
//     ],
//     AI_Products: [
//       {
//         name: t('InputOutput'),
//         text: [t('InputOutputT')],
//       },
//       {
//         name: t('OwnershipRights'),
//         text: [t('OwnershipRightsT')],
//       },
//       {
//         name: t('ServiceProviders'),
//         text: [t('ServiceProvidersT')],
//       },
//       {
//         name: t('AvailabilityModi'),
//         text: [t('AvailabilityModiT')],
//       },
//     ],
//     Service_Commitment: [
//       {
//         name: t('8Definitions'),
//         text: [t('8DefinitionsT1'), t('8DefinitionsT2'), t('8DefinitionsT3')],
//       },
//       {
//         name: t('Commitment'),
//         text: [t('CommitmentT1'), t('CommitmentT2')],
//       },
//     ],
//     Customer_Support: [
//       {
//         name: t('PhoneSupport'),
//         text: [t('PhoneSupportT')],
//       },
//       {
//         name: t('EmailSupport'),
//         text: [t('EmailSupportT')],
//       },
//       {
//         name: t('SupportLimitations'),
//         text: [t('SupportLimitationsT')],
//       },
//       {
//         name: t('FreeServices'),
//         text: [t('FreeServicesT1'), t('FreeServicesT2')],
//       },
//     ],
//   };

//   return lists[key];
// };

export default ProductSpecificContent;
