import React from 'react';
import BulletPoints from '../BulletPoints';

const CommonPrivacyWithBullets = ({
  title,
  count,
  data,
  pl,
  id,
  handleOnClick,
}) => {
  return (
    <div className="mt-[30px] flex flex-col" id={id}>
      <p className="Text24Legal">
        {count}. <span className="pl-1">{title}</span>
      </p>
      <div className="flex flex-col gap-4">
        {data.map((info, index1) => (
          <React.Fragment key={index1}>
            <div
              className={`flex mt-4 gap-2`}
              style={{ paddingLeft: pl ? pl : '' }}
            >
              <p className="w-3 Text18Normal text-[18px] font-semibold text-SecondaryT">
                {info.id}.
              </p>
              <p className="w-[calc(100%-20px)] Text18Normal text-[18px] font-semibold text-SecondaryT">
                {info.name}
              </p>
            </div>
            <p
              className="Text16L"
              key={index1}
              dangerouslySetInnerHTML={{ __html: info.text }}
              onClick={handleOnClick}
            ></p>
            {info.points && (
              <>
                {info.points.map((point, index4) => (
                  <BulletPoints
                    data={point}
                    key={index4}
                    pl="20px"
                    handleOnClick={handleOnClick}
                  />
                ))}
              </>
            )}
            {info.textBottom && (
              <>
                {info.textBottom.map((textT, index1) => (
                  <p
                    className="Text16L"
                    key={index1}
                    dangerouslySetInnerHTML={{ __html: textT }}
                    onClick={handleOnClick}
                  ></p>
                ))}
              </>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default CommonPrivacyWithBullets;
